import styles from './Table.less';

import { useMsal } from "@azure/msal-react";
import { ActionButton, CheckboxVisibility, CommandBar, DetailsList, DetailsListLayoutMode, GroupHeader, IColumn, IColumnReorderOptions, ICommandBarItemProps, IDetailsColumnRenderTooltipProps, IDetailsHeaderProps, IDetailsListStyles, IGroup, IGroupHeaderProps, IIconProps, IRenderFunction, IToggleStyles, SelectionMode, Separator, TextField, Toggle, TooltipHost } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { Selection } from '@fluentui/react/lib/Selection';
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useEFTrackerBigBetsLatestByProjectId, useEFTrackerBigBetsProject } from '../../../../../hooks/useEfficiencyTrackerProject';
import { useCategoryFilters } from '../../../../../hooks/useFilters';
import { useGotoPage } from '../../../../../hooks/useGotoPage';
import { CategoryDivision, CategoryDivisionDisplayName } from '../../../../../models/CategoryDivision';
import { EfficiencyStatusEnum } from '../../../../../models/EfficiencyTracker';
import { BigBetsMetrics, EFTrackerBigBetsLatestByProject, EFTrackerBigBetsProject } from '../../../../../models/EfficiencyTrackerBigBets';
import { EventType, LogComponent, LogElement, LogTarget } from '../../../../../models/LogModel';
import { Pages } from '../../../../../models/Nav';
import { trackEventCallback } from '../../../../../utils/AppInsights';
import { currencyFormatter, currencyFormatterNegativeFirst } from '../../../../../utils/currency';
import LoadingState from '../../../../ResponseBoundary/LoadingState';
import FiltersDropDown from '../../../../common/FiltersBanner/FiltersDropDown/FiltersDropDown';
import WorkloadFilter from '../../../../common/FiltersBanner/WorkloadFilter/WorkloadFilter';
import EmptyState from '../../../../common/state/EmptyState';
import EfficiencyStatus from '../../Tools/EfficiencyStatus';
import { checkIsDRI, formatValue } from '../../Tools/ExportFunction';
import Mail2Person from '../../Tools/Mail2Person';
import MoreVerticalsBigBets from '../Tools/MoreVerticalsBigBets';

const searchIconProps: IIconProps = { iconName: 'Search', styles: { root: { color: 'blue' } } };

const columnKeys = {
    Index: 'Index',
    ProjectId: 'ProjectId',
    Category: 'Category',
    Workload: 'Workload',
    Name: 'Name',
    MoreVertical: 'MoreVertical',
    DRI: 'DRI',
    CVPSponsor: 'CVPSponsor',
    GEMGPM: 'GEMGPM',
    ImpactDollar: 'ImpactDollar',
    LatestImpact: 'LatestImpact',
    DeliveryETA: 'DeliveryETA',
    ConfidenceInImpact: 'ConfidenceInImpact',
    ExecutionStatus: 'ExecutionStatus',
    LatestNote: 'LatestNote',
    LinkToTicket: 'LinkToTicket',
    Notes: 'Notes',
    Owner: 'Owner',
    CreateTime: 'CreateTime',
    LastModifyTime: 'LastModifyTime',
};

const columns: IColumn[] = [
    {
        key: columnKeys.Index, name: 'Index', minWidth: 20, maxWidth: 60, isResizable: false, className: styles.PrimaryColumn
    },
    {
        key: columnKeys.Category, name: 'Category', minWidth: 20, maxWidth: 120, isResizable: false, className: styles.PrimaryColumn
    },
    {
        key: columnKeys.Name, name: 'Name', minWidth: 30, maxWidth: 150, isResizable: true, className: styles.PrimaryColumn
    },
    {
        key: columnKeys.MoreVertical, name: '', minWidth: 15, maxWidth: 15, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: columnKeys.Workload, name: 'Workload', minWidth: 20, maxWidth: 100, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: columnKeys.DRI, name: 'DRI', minWidth: 60, maxWidth: 80, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: columnKeys.CVPSponsor, name: 'CVP Sponsor', minWidth: 60, maxWidth: 100, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: columnKeys.GEMGPM, name: 'GEM/GPM', minWidth: 60, maxWidth: 80, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: columnKeys.ImpactDollar, name: 'Target Impact', minWidth: 90, maxWidth: 90, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: columnKeys.LatestImpact, name: 'Latest Impact', minWidth: 90, maxWidth: 90, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: columnKeys.DeliveryETA, name: 'Delivery ETA', minWidth: 30, maxWidth: 80, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: columnKeys.ConfidenceInImpact, name: 'Confidence', minWidth: 60, maxWidth: 90, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: columnKeys.ExecutionStatus, name: 'Execution status', minWidth: 60, maxWidth: 110, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: columnKeys.LatestNote, name: 'Latest Note', minWidth: 20, maxWidth: 150, isResizable: true, className: styles.nomarlColumn
    },
    // Temporary hiding
    //{
    //    key: columnKeys.LinkToTicket, name: 'Ticket', minWidth: 20, maxWidth: 40, isResizable: false, className: styles.nomarlColumn
    //},
    {
        key: columnKeys.Notes, name: 'Description', minWidth: 20, maxWidth: 150, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: columnKeys.CreateTime, name: 'Create Date', minWidth: 50, maxWidth: 80, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: columnKeys.LastModifyTime, name: 'Last Modify Date', minWidth: 50, maxWidth: 110, isResizable: false, className: styles.nomarlColumn
    },
];


//Handle Sticky header
const focusZoneProps = {
    className: styles.focusZone,
    'data-is-scrollable': 'true',
} as React.HTMLAttributes<HTMLElement>;

const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
        return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
        <TooltipHost {...tooltipHostProps} />
    );
    return defaultRender!({
                ...props,
                onRenderColumnHeaderTooltip,
    });
};

//Set max width for table
const totalMaxWidth = columns.reduce((sum, column) => sum + (column.maxWidth || 0), 450);

const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: 'auto',
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                position: 'relative',
                overflowY: 'auto',
                width: '100%',
            },
            // First 1+3 column cell
            '& .ms-DetailsRow-cell:nth-child(1)': {
                position: 'sticky',
                left: 0,
                zIndex: 10,
                backgroundColor: 'white',
            },
            '& .ms-DetailsRow-cell:not(.ms-DetailsRow-cellCheck):nth-child(1)': {
                position: 'sticky',
                left: '48px',
                zIndex: 9,
                backgroundColor: 'white',
            },
            '& .ms-DetailsRow-cell:nth-child(2)': {
                position: 'sticky',
                left: '128px',
                zIndex: 8,
                backgroundColor: 'white',
            },
            '& .ms-DetailsRow-cell:nth-child(3)': {
                position: 'sticky',
                left: '268px',
                zIndex: 7,
                backgroundColor: 'white',
            },

            // First 1+3 column header
            '& .ms-DetailsHeader-cell:nth-child(1)': {
                position: 'sticky',
                left: 0,
                zIndex: 10,
                backgroundColor: 'white',
            },
            '& .ms-DetailsHeader-cell:nth-child(2)': {
                position: 'sticky',
                left: '48px',
                zIndex: 9,
                backgroundColor: 'white',
            },
            '& .ms-DetailsHeader-cell:nth-child(3)': {
                position: 'sticky',
                left: '128px',
                zIndex: 8,
                backgroundColor: 'white',
            },
            '& .ms-DetailsHeader-cell:nth-child(4)': {
                position: 'sticky',
                left: '268px',
                zIndex: 7,
                backgroundColor: 'white',
            },
        },
    },
    headerWrapper: {
        position: 'sticky',
        top: 0,
        zIndex: 12,
    },
    contentWrapper: {
        overflowY: 'visible',
        overflowX: 'visible',
        height: '80vh',
    },
};

const gridStylesGroup: Partial<IDetailsListStyles> = {
    root: {
        overflowX: 'auto',
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                position: 'relative',
                overflowY: 'auto',
                width: '100%',
            },
            // First 1+1+3 column cell
            '& .ms-DetailsRow-cell:nth-child(1)': {
                position: 'sticky',
                left: 0,
                zIndex: 10,
                backgroundColor: 'white',
            },
            '& .ms-GroupSpacer': {
                position: 'sticky',
                left: 48,
                zIndex: 10,
                backgroundColor: 'white',
            },
            '& .ms-DetailsRow-cell:not(.ms-DetailsRow-cellCheck):nth-child(1)': {
                position: 'sticky',
                left: 48 + 36,
                zIndex: 9,
                backgroundColor: 'white',
            },
            '& .ms-DetailsRow-cell:nth-child(2)': {
                position: 'sticky',
                left: 48 + 36 + 80,
                zIndex: 8,
                backgroundColor: 'white',
            },
            '& .ms-DetailsRow-cell:nth-child(3)': {
                position: 'sticky',
                left: 48 + 36 + 80 + 140,
                zIndex: 7,
                backgroundColor: 'white',
            },

            // First 1+1+3 column header
            '& .ms-DetailsHeader-cell:nth-child(1)': {
                position: 'sticky',
                left: 0,
                zIndex: 10,
                backgroundColor: 'white',
            },
            '& .ms-DetailsHeader-cell:nth-child(2)': {
                position: 'sticky',
                left: 48,
                zIndex: 9,
                backgroundColor: 'white',
            },
            '& .ms-DetailsHeader-cell:nth-child(3)': {
                position: 'sticky',
                left: 48 + 36,
                zIndex: 8,
                backgroundColor: 'white',
            },
            '& .ms-DetailsHeader-cell:nth-child(4)': {
                position: 'sticky',
                left: 48 + 36 + 80,
                zIndex: 7,
                backgroundColor: 'white',
            },
            '& .ms-DetailsHeader-cell:nth-child(5)': {
                position: 'sticky',
                left: 48 + 36 + 80 + 140,
                zIndex: 7,
                backgroundColor: 'white',
            },
        },
        '.ms-GroupHeader': {
            width: 500,
            position: 'sticky',
            left: 0,
            zIndex: 10,
        },
    },
    headerWrapper: {
        position: 'sticky',
        top: 0,
        zIndex: 12,
    },
    contentWrapper: {
        overflowY: 'visible',
        overflowX: 'visible',
        height: '80vh',
    },
};

const priorityMap: Partial<Record<CategoryDivision | EfficiencyStatusEnum, number>> = {
    //Execution Status
    [EfficiencyStatusEnum.OnTrack]: 5,
    [EfficiencyStatusEnum.Delayed]: 4,
    [EfficiencyStatusEnum.AtRisk]: 3,
    [EfficiencyStatusEnum.Draft]: 2,
    [EfficiencyStatusEnum.Archive]: 1,
    //Filter
    [CategoryDivision.Category]: 4,
    [CategoryDivision.Workload]: 3,
    [CategoryDivision.Confidence]: 2,
    [CategoryDivision.ExecutionStatus]: 1,
    //Confidence
    [EfficiencyStatusEnum.High]: 3,
    [EfficiencyStatusEnum.Medium]: 2,
    [EfficiencyStatusEnum.Low]: 1
};
function getPriority(key: CategoryDivision | EfficiencyStatusEnum): number { // Avoid undefined
    return priorityMap[key] ?? 0;
}

const SupportedFilters = [
    CategoryDivision.Category,
    CategoryDivision.Workload,
    CategoryDivision.Confidence,
    CategoryDivision.ExecutionStatus,
];

function isSearchableFilter(
    category: CategoryDivision,
) {
    switch (category) {
        case CategoryDivision.Category:
        case CategoryDivision.Workload:
            return true;
        case CategoryDivision.Confidence:
        case CategoryDivision.ExecutionStatus:
            return false;
        default:
            return false;
    }
}

const PivotByCategory = "PivotByCategory";

interface IProjectDetailsTable {
    IsMyProject: boolean;
}

const BigBetsProjectTable: React.FC<IProjectDetailsTable> = (props) => {
    //Get location & account & param
    const gotoPage = useGotoPage();
    const { instance } = useMsal();
    const location = useLocation();
    const account = instance.getActiveAccount();
    const params = new URLSearchParams(window.location.search);

    //Get project & latest result
    const [allProject, setAllProject] = useState<EFTrackerBigBetsProject[]>([]);
    const [filteredProject, setFilteredProject] = useState<EFTrackerBigBetsProject[]>([]);
    const [showProject, setShowProject] = useState<EFTrackerBigBetsProject[]>([]);
    const [projectLatestResults, setProjectLatestResults] = useState<EFTrackerBigBetsLatestByProject[]>([]);
    const { projects: project, isLoading, refetch, isRefetching } = useEFTrackerBigBetsProject();
    const { results: latestPair, isLoading: isLoadingActuals, refetch: refetchActuals, isRefetching: isRefetchingAcutals } = useEFTrackerBigBetsLatestByProjectId(allProject.map(item => item.ProjectID));
    const [tableVisible, { setFalse: setTableVisibleDisable, setTrue: setTableVisibleEnable }] = useBoolean(false);

    //Handle group by category
    const [projectGroup, setGroup] = useState<IGroup[] | undefined>([]);
    const [groupByCategoryFlag, { setFalse: setgroupByCategoryDisable, setTrue: setgroupByCategoryEnable }] = useBoolean(params.get(PivotByCategory) === 'true' ? true : false);

    // Refresh table
    const [refreshIndex, setRefreshIndex] = useState<number>(0);

    //Get Columns
    const [newColumns, setnewColumns] = useState<IColumn[]>(columns || []);

    //Select
    const [selectDismiss, { setFalse: setSelectDismissDone, setTrue: setSelectDismiss }] = useBoolean(false);
    const [selectionCount, setSelectionCount] = React.useState<string>('');
    const [totalImpact, setTotalImpact] = React.useState<number>(0);
    const [latestImpact, setlatestImpact] = React.useState<number>(0);
    const [selectedIndices, setSelectedIndices] = useState<number[]>([]); //For Download

    const [nameFilterString, setNameFilterString] = React.useState<string>('');

    //Global Filters
    const [selectedFilters, setSelectedFilters] = useState<CategoryDivision[]>(SupportedFilters);
    const { filters: globalFilters, clearAllFiltersOnly } = useCategoryFilters();

    /***** Global Filters ********************************/
    // Toggle for group by category

    const onToggleChange = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        checked ? setgroupByCategoryEnable() : setgroupByCategoryDisable();
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set(PivotByCategory, checked ? "true" : "false");
        window.history.pushState({}, '', currentUrl);

        //Track
        trackEventCallback(LogComponent.EffiencyTracker, LogElement.EfficiencyTrackerBigBets, "GroupByCategory-" + checked ? "True" : "False", LogTarget.Toggle, undefined, EventType.Click);
    };
    const toggleStyles: IToggleStyles = {
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 0,
            marginRight: 10,
        },
        label: {
            marginRight: 10,
            marginBottom: 0,
        },
        container: undefined,
        pill: undefined,
        thumb: undefined,
        text: undefined
    };

    // Global fiter command bar item
    const selectedfilterItems = selectedFilters.map((filter) => ({
        key: `${filter}Filter`, // unique key
        onRender: () => (
            <div className={styles.Filter}>
                <WorkloadFilter
                    hidden={!selectedFilters.includes(filter)}
                    category={filter}
                    optionList={getFilterList(filter)}
                    displayName={CategoryDivisionDisplayName[filter]}
                    isSearchable={isSearchableFilter(filter)}
                />
            </div>
        ),
    }));

    const filterBarItem: ICommandBarItemProps[] = [
        {
            key: 'GroupByCagetory',
            onRender: () => (
                <div className={styles.Filter}>
                    <Toggle
                        label="Group By Category"
                        checked={groupByCategoryFlag}
                        onChange={onToggleChange}
                        styles={toggleStyles}
                    />
                </div>
            ),
        },
        {
            key: 'FilterManage',
            onRender: () => (
                <div className={styles.Filter}>
                    <FiltersDropDown
                        selectedKeys={selectedFilters}
                        onChange={(newSelectedFilters: CategoryDivision[]) => {
                            const sortedFilters = newSelectedFilters.sort((a: CategoryDivision, b: CategoryDivision) => { //order first
                                return getPriority(a) < getPriority(b) ? 1 : -1;
                            });

                            setSelectedFilters(sortedFilters); //set oredered filter

                            //Track
                            trackEventCallback(LogComponent.EffiencyTracker, LogElement.EfficiencyTrackerBigBets, "Change Filter", LogTarget.Button, undefined, EventType.Click);
                        }}
                        supportedFilters={SupportedFilters}
                    />
                    <ActionButton key="clear" iconProps={{ iconName: "ClearFilter" }} onClick={onClearAll} style={{ padding: 0 }}>
                        Reset all
                    </ActionButton>
                </div>
            ),
        },
        ...selectedfilterItems,
    ];

    // Get all fiter list for each CategoryDivision
    function getFilterList(
        category: CategoryDivision,
    ) {
        switch (category) {
            case CategoryDivision.Category:
                return Array.from(
                    new Set(allProject.map(item => item.Category.trim()))
                ).sort();
            case CategoryDivision.Workload:
                return Array.from(
                    new Set(allProject.map(item => item.Workload.trim()))
                ).sort();
            case CategoryDivision.Confidence:
                return [EfficiencyStatusEnum.High, EfficiencyStatusEnum.Medium, EfficiencyStatusEnum.Low];
            case CategoryDivision.ExecutionStatus:
                return [EfficiencyStatusEnum.OnTrack, EfficiencyStatusEnum.Delayed, EfficiencyStatusEnum.AtRisk, EfficiencyStatusEnum.Draft, EfficiencyStatusEnum.Archive];
            default:
                return [];
        }
    }

    //Get all used CategoryDivision
    function getSelectedFiltersFromGlobalFilter() {
        const filters = globalFilters.filters;
        const tempSelectedFilters = new Set<CategoryDivision>([]);
        selectedFilters.forEach((item) => { // Insert current filter first
            tempSelectedFilters.add(item);
        });

        SupportedFilters.forEach((filterKey) => { // Add global filter then
            if ((filters[filterKey]?.length || 0) > 0) {
                tempSelectedFilters.add(filterKey);
            }
        });

        return tempSelectedFilters;
    }

    //Clear all Global Filters
    const onClearAll = useCallback(() => {
        clearAllFiltersOnly()
        trackEventCallback(LogComponent.PivotHeadPane, LogElement.ClearAll, "Clear all", LogTarget.Button);
    }, [clearAllFiltersOnly]);

    /***** CommandBar Item ********************************/
    //Download format
    function escapeCSVValue(value: string): string {
        if (typeof value === 'string') {
            value = value.replace(/"/g, '""');
            if (value.includes(',') || value.includes('\n') || value.includes('\r') || value.includes('"') || value.includes('/') || value.includes('#')) {
                value = `"${value}"`;
            }
        }
        return value;
    }

    function convertItemToCSVRow(item: EFTrackerBigBetsProject): string {
        const result = projectLatestResults.find(result => result.ProjectID === item.ProjectID);
        const csvValues = [
            item.ProjectID,
            escapeCSVValue(item.Category),
            escapeCSVValue(item.Workload),
            escapeCSVValue(item.Name),
            escapeCSVValue(item.DRI),
            escapeCSVValue(item.Metrics ? (JSON.parse(item.Metrics) as unknown as BigBetsMetrics).CVPSponsor : "null"),
            escapeCSVValue(item.Metrics ? (JSON.parse(item.Metrics) as unknown as BigBetsMetrics).GEMGPM : "null"),
            item.ImpactDollar,
            result ? result.LatestActual : 0,
            item.DeliveryETA.toLocaleDateString(),
            item.ConfidenceInImpact,
            item.ExecutionStatus,
            (result !== undefined && result?.Discussion !== null) ? escapeCSVValue(result.Discussion.Note) : "null",
            escapeCSVValue(item.Notes),
            escapeCSVValue(item.LinksToExistingTicketItem),
            escapeCSVValue(item.Owner),
            item.CreateTime.toISOString(),
            item.LastModifyTime.toISOString(),
        ];

        return csvValues.join(',');
    }

    // Download 
    const _download = () => {
        const selectedItems = selectedIndices.map(index => showProject[index]);
        const customHeaders = [
            columnKeys.ProjectId,
            columnKeys.Category,
            columnKeys.Workload,
            columnKeys.Name,
            columnKeys.DRI,
            columnKeys.CVPSponsor,
            columnKeys.GEMGPM,
            columnKeys.ImpactDollar,
            columnKeys.LatestImpact,
            columnKeys.DeliveryETA,
            columnKeys.ConfidenceInImpact,
            columnKeys.ExecutionStatus,
            columnKeys.LatestNote,
            columnKeys.Notes,
            columnKeys.LinkToTicket,
            columnKeys.Owner,
            columnKeys.CreateTime,
            columnKeys.LastModifyTime,
        ];

        const csvHeader = customHeaders.join(',');
        const csvRows = selectedItems.map(item => convertItemToCSVRow(item));

        // Combine header and rows into CSV content
        const csvContent = [csvHeader, ...csvRows].join('\n');

        // Create a new Blob object for the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);

        // Set the download URL and filename
        link.setAttribute("href", url);
        link.setAttribute("download", `BigBets-SelectedProject.csv`);
        document.body.appendChild(link);

        // Programmatically click the link to trigger download
        link.click();

        // Clean up and revoke the object URL
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    //Far commandBar Items
    const farItems: ICommandBarItemProps[] = [
        {
            key: 'TotalImpact',
            text: "Total Impact " + currencyFormatter(totalImpact, 2, "$"),
            disabled: true,
        },
        {
            key: 'LatestImpact',
            text: "Latest Impact " + currencyFormatter(latestImpact, 2, "$"),
            disabled: true,
        },
        {
            key: 'SelectProjectCount',
            text: selectionCount,
            disabled: true,
        },
    ];

    //Command Bar items
    const commandBarItem: ICommandBarItemProps[] = [
        {
            key: 'AddProject',
            text: 'Add project',
            iconProps: { iconName: 'Add' },
            onClick: () => {
                const project = props.IsMyProject ? Pages.MyProjects : Pages.AllProjects;
                gotoPage(`${Pages.EfficiencyTrackerV2}/${project}/${Pages.BigBetsTracker}/${Pages.EFAddProjectPage}`)
            },
        },
        {
            key: 'DownloadProject',
            text: 'Export',
            iconProps: { iconName: 'Download' },
            onClick: () => _download(),
            disabled: selectedIndices.length == 0,
        },
        {
            key: 'RefreshProject',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: () => {
                refetch();
                refetchActuals();
                setRefreshIndex(refreshIndex + 1);
            },
        },
        {
            key: 'FilterProject',
            onRender: () => (
                <TextField
                    className={styles.Filter}
                    style={{width: 150}}
                    iconProps={ searchIconProps }
                    placeholder="Filter by name"
                    value={nameFilterString}
                    onChange={(ev, text) => _onFilterByName(ev, text)}
                />
            ),
        },
    ];

    //Fiter By Name
    const _onFilterByName = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string | undefined): void => {
        setNameFilterString(text || "");
        const tmpProject = text ? filteredProject.filter(i => {
            return i.Name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        }) : filteredProject;

        //Track
        if (text) {
            trackEventCallback(LogComponent.EffiencyTracker, LogElement.EfficiencyTrackerBigBets, "FilterByName-" + text, LogTarget.Button, undefined, EventType.Click);
        }

        const tmpTotalImpact = tmpProject.reduce((acc, item) => acc + (item.ImpactDollar || 0), 0);
        const tmpLatestImpact = tmpProject.reduce((acc, item) => acc + (projectLatestResults.find(result => result.ProjectID === item.ProjectID)?.LatestActual || 0), 0);

        if (groupByCategoryFlag) {
            const [group, groupProjects] = groupPlans(tmpProject, projectLatestResults);
            setShowProject(groupProjects);
            setGroup(group);
        } else {
            setShowProject(tmpProject);
        }
      
        setSelectionCount(`Total ${tmpProject.length} projects`);
        setTotalImpact(tmpTotalImpact);
        setlatestImpact(tmpLatestImpact);
    };


    /***** All Status Change ********************************/
    // Set Global filters and filterd project list.
    useEffect(() => {
        const filters = globalFilters.filters;
        const categoryList = filters.Category;
        const workloadList = filters.Workload;
        const ConfidenceList = filters.Confidence;
        const ExecutionStatusList = filters.ExecutionStatus;

        const tmpProjects = [...allProject];

        const tmpFilterProject = tmpProjects.filter(item => {
            const categoryFilter = categoryList?.length ? categoryList.includes(item.Category) : true;
            const workloadFilter = workloadList?.length ? workloadList.includes(item.Workload) : true;
            const ConfidenceFilter = ConfidenceList?.length ? ConfidenceList.includes(item.ConfidenceInImpact) : true;
            const ExecutionStatusFilter = ExecutionStatusList?.length ? ExecutionStatusList.includes(item.ExecutionStatus) : true;
            return categoryFilter && workloadFilter && ConfidenceFilter && ExecutionStatusFilter;
        });

        setSelectedFilters([...getSelectedFiltersFromGlobalFilter()]);
        setFilteredProject(tmpFilterProject);
        setNameFilterString("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalFilters.filters, allProject, props.IsMyProject]);


    //Get latest result for each project
    useEffect(() => {
        if (isLoadingActuals || isRefetchingAcutals) {
            setProjectLatestResults([]);
            return;
        }
        if (!latestPair) return;

        setProjectLatestResults(latestPair);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingActuals, isRefetchingAcutals, allProject]);

    //Get project
    useEffect(() => {
        if (isLoading || isRefetching) {
            setAllProject([]);
            setFilteredProject([]);
            setTableVisibleDisable();
            return;
        }
        if (!project) return;
        const tmpProjects = project.filter(item => {
            const statusFilter = item.ExecutionStatus !== EfficiencyStatusEnum.Deleted.toString(); //exclude Delete plan.
            const isMyProjectFilter = props.IsMyProject ? checkIsDRI(item.DRI, account?.username || '') || item.Owner === account?.username : true;
            return statusFilter && isMyProjectFilter;
        });

        //Set project to table
        setAllProject(tmpProjects);
        setFilteredProject(tmpProjects);
        setTableVisibleEnable();
        setnewColumns(columns);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account?.username, isLoading, isRefetching, props.IsMyProject]);

    //Update show project list
    useEffect(() => {
        if (groupByCategoryFlag) {
            const [group, groupProjects] = groupPlans(filteredProject, projectLatestResults);
            setShowProject(groupProjects);
            setGroup(group);
        } else {
            setShowProject(filteredProject);
        }
    }, [filteredProject, groupByCategoryFlag, projectLatestResults]);

    //Update Impact & Count
    useEffect(() => {
        const tmpTotalImpact = showProject.reduce((acc, item) => acc + (item.ImpactDollar || 0), 0);

        const tmpLatestImpact = showProject.reduce((acc, item) => acc + (projectLatestResults.find(result => result.ProjectID === item.ProjectID)?.LatestActual || 0), 0);

        setTotalImpact(tmpTotalImpact);
        setlatestImpact(tmpLatestImpact);
        setSelectionCount(`Total ${showProject.length} projects`);

        if (selectDismiss) {
            setSelectDismissDone();
        }
    }, [showProject, selectDismiss, setSelectDismissDone, projectLatestResults]);

    /***** DetailList ********************************/
    // DetailList Selection
    const _selection = new Selection({
        onSelectionChanged: () => {
            const selectedItems = _selection.getSelection() as EFTrackerBigBetsProject[];

            if (selectedItems.length > 0) { //Selected projects
                const tmpTotalImpact = selectedItems.reduce((acc, item) => acc + (item.ImpactDollar || 0), 0);
                const tmpLatestImpact = selectedItems.reduce((acc, item) => acc + (projectLatestResults.find(result => result.ProjectID === item.ProjectID)?.LatestActual || 0), 0);

                setTotalImpact(tmpTotalImpact);
                setlatestImpact(tmpLatestImpact);
                setSelectionCount(_getSelectionDetails(_selection.getSelectedCount()));
            } else {
                setSelectDismiss();
            }

            setSelectedIndices(_selection.getSelectedIndices());
        },
    });

    const _getSelectionDetails = (count: number): string => {
        switch (count) {
            case 1:
                return 'Selected 1 project ';
            default:
                return `Selected ${count} projects `;
        }
    };

    // Reorder Column
    const _onColumnClick = (event?: React.MouseEvent<HTMLElement>, column?: IColumn): void => {
        let sortedItems = [...showProject];
        if (!column || !column.key || column.key  === 'Index') return;
        let isSortedDescending = column.isSortedDescending;

        // If sorted this column, flip it.
        if (column.isSorted) {
            isSortedDescending = !isSortedDescending;
        }

        // Sort the items.
        sortedItems = _copyAndSort(sortedItems, column.key, isSortedDescending);

        setFilteredProject(sortedItems);
        setnewColumns(newColumns.map((col: IColumn) => {
            const newCol = { ...col };
            newCol.isSorted = newCol.key === column.key;

            if (newCol.isSorted) {
                newCol.isSortedDescending = isSortedDescending;
            }

            return newCol;
        }));
    };

    function _onItemInvoked(item: EFTrackerBigBetsProject): void {
        gotoPage(`${location.pathname}/${Pages.EFSubPage}/${item.ProjectID}`)
    }

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        if (columnKey === columnKeys.ConfidenceInImpact || columnKey === columnKeys.ExecutionStatus) {
            return [...items].sort((a: T, b: T) => {
                return ((isSortedDescending ? getPriority(a[key] as EfficiencyStatusEnum) < getPriority(b[key] as EfficiencyStatusEnum) : getPriority(a[key] as EfficiencyStatusEnum) > getPriority(b[key] as EfficiencyStatusEnum)) ? 1 : -1);
            });
        }

        return [...items].sort((a: T, b: T) => {
            return ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1);
        });
    }

    // Handle column drag
    const _getColumnReorderOptions = (): IColumnReorderOptions => {
        return {
            frozenColumnCountFromStart: 4,
            handleColumnReorder: _handleColumnReorder,
        };
    }
    const  _handleColumnReorder = (draggedIndex: number, targetIndex: number):void => {
        const draggedItems = newColumns[draggedIndex];
        const reorderColumns: IColumn[] = [...newColumns];

        // insert before the dropped item
        reorderColumns.splice(draggedIndex, 1);
        reorderColumns.splice(targetIndex, 0, draggedItems);
        setnewColumns(reorderColumns);
    };

    //Handle row item
    function _renderItemColumn(item: EFTrackerBigBetsProject, index?: number, column?: IColumn): React.ReactNode {
        let fieldContent;
        const latestProject = projectLatestResults.find(result => result.ProjectID === item.ProjectID);
        switch (column?.key) {
            case columnKeys.CVPSponsor:
                fieldContent = item.Metrics ? (JSON.parse(item.Metrics) as unknown as BigBetsMetrics).CVPSponsor : "null";
                break
            case columnKeys.GEMGPM:
                fieldContent = item.Metrics ? (JSON.parse(item.Metrics) as unknown as BigBetsMetrics).GEMGPM : "null";
                break;
            case columnKeys.ImpactDollar:
                if (item.ImpactDollar === 0) {
                    fieldContent = "TBD"
                } else {
                    fieldContent = currencyFormatter(item.ImpactDollar, 2, "$");
                }
                break;
            case columnKeys.LatestImpact:
                if (latestProject === undefined) {
                    fieldContent = "loading"
                } else {
                    const actual = latestProject.LatestActual;
                    if (actual === undefined) {
                        fieldContent = "0";
                    } else {
                        fieldContent = currencyFormatter(actual, 2, "$");
                    }
                }
                break;
            case columnKeys.LatestNote:
                if (latestProject === undefined) {
                    fieldContent = "loading"
                } else {
                    const dicussion = latestProject.Discussion;
                    if (dicussion === undefined || dicussion === null) {
                        fieldContent = "null";
                    } else {
                        fieldContent = dicussion.Note;
                    }
                }
                break;
            default:
                fieldContent = item[column?.key as keyof EFTrackerBigBetsProject];
        }

        const trendLastExecutionStatus = latestProject === undefined ?
            undefined : latestProject?.LastExecutionStatus === "" ?
                undefined : (latestProject.LastExecutionStatus === EfficiencyStatusEnum.Archive
                    || latestProject.LastExecutionStatus === EfficiencyStatusEnum.Draft
                    || item.ExecutionStatus === EfficiencyStatusEnum.Archive
                ) ?
                    undefined : getPriority(item.ExecutionStatus as EfficiencyStatusEnum) - getPriority(latestProject?.LastExecutionStatus as EfficiencyStatusEnum);

        const trendLastConfidenceInImpact = latestProject === undefined ?
            undefined : latestProject?.LastConfidenceInImpact === "" ?
                undefined : getPriority(item.ConfidenceInImpact as EfficiencyStatusEnum) - getPriority(latestProject?.LastConfidenceInImpact as EfficiencyStatusEnum);

        
        switch (column?.key) {
            case columnKeys.Index:
                return <span>{formatValue((index || 0) + 1)}</span>;
            case columnKeys.Category:
            case columnKeys.Name:
                return <span onClick={() => _onItemInvoked(item)} style={{ cursor: 'pointer' }}>{formatValue(fieldContent)}</span>;
            case columnKeys.MoreVertical:
                return <MoreVerticalsBigBets ProjectId={item.ProjectID} AllowDelete={item.Owner === account?.username} />;
            case columnKeys.ExecutionStatus:
                return <EfficiencyStatus Status={item.ExecutionStatus as EfficiencyStatusEnum} trend={trendLastExecutionStatus} />;
            case columnKeys.ConfidenceInImpact:
                return <EfficiencyStatus Status={item.ConfidenceInImpact as EfficiencyStatusEnum} trend={trendLastConfidenceInImpact} />;
            case columnKeys.LinkToTicket:
                return <a href={item.LinksToExistingTicketItem}>Link</a>;
            case columnKeys.DRI:
                return <Mail2Person users={item.DRI} />;
            case columnKeys.ImpactDollar:
                return (
                    <div style={{ textAlign: "right", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        <span>{fieldContent}</span>
                        {latestProject?.LastTarget !== undefined && latestProject.LastTarget !== 0 && item.ImpactDollar !== latestProject.LastTarget && (
                            <div className={item.ImpactDollar > latestProject.LastTarget ? styles.green : styles.red}>
                                <span>({currencyFormatterNegativeFirst(item.ImpactDollar - latestProject.LastTarget, 2, item.ImpactDollar > latestProject.LastTarget ? "+$" : "$")})</span>
                            </div>
                        )}
                    </div>
                );
            case columnKeys.LatestImpact:
                return (
                    <div style={{ textAlign: "right" }}>
                        <span>{fieldContent}</span>
                    </div>
                );
            default:
                return <span>{formatValue(fieldContent)}</span>;
        }
    }

    //Handle group header
    const handlePreventDoubleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
    };

    const renderTitle: IRenderFunction<IGroupHeaderProps> = (props) => {
        if (!props) return null;

        const { data } = props.group || {};
        const category = data?.[0] || '';
        const total = data?.[1] || '';
        const actual = data?.[2] || '';
        const count = props.group?.count || 0;

        return (
            <div onDoubleClick={handlePreventDoubleClick}
                style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'auto',
                whiteSpace: 'nowrap',
                width: 700,
            }}>
                <div style={{
                    display: 'flex',
                    width: '50%',
                    textAlign: 'left',
                    paddingLeft: 10,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                }}>
                    <div style={{ fontWeight: 700, paddingRight: 5 }}>Category:</div>
                    {category}({count})
                </div>
                <div style={{ display: 'flex', width: '25%', textAlign: 'right' }}>
                    <div style={{ fontWeight: 700, paddingRight: 5 }}>Total Impact:</div>
                    {total}
                </div>
                <div style={{ display: 'flex', width: '25%', textAlign: 'right' }}>
                    <div style={{ fontWeight: 700, paddingRight:5 }}>Latest Impact:</div>
                    {actual}
                </div>
            </div>
        );
    };



    const onRenderHeader: IRenderFunction<IGroupHeaderProps> = (props) => {
        if (!props) return null;

        return (
            <div onDoubleClick={handlePreventDoubleClick}>
                <GroupHeader
                    {...props}
                    onGroupHeaderClick={undefined}
                    styles={{
                        title: {
                            cursor: 'auto',
                        },
                    }}
                    onRenderTitle={
                        renderTitle
                    }
                />
            </div>
        );
    };

    return (
        <div style={{ maxWidth: totalMaxWidth }}>
            <Separator styles={{ root: styles.Separator }} />
            <CommandBar
                styles={{
                    root: {
                        height: '32px',
                    },
                }}
                key={"CommandBar_filter"}
                items={filterBarItem}
            />
            <CommandBar
                key={"CommandBar_" + props.IsMyProject}
                items={commandBarItem}
                farItems={farItems}
            />
            {
                tableVisible ? (
                    allProject.length > 0 ?
                        <div style={{ flex: 1, overflow: 'hidden' }}>
                            {!groupByCategoryFlag ? (
                                <DetailsList 
                                    styles={gridStyles}
                                    compact={false}
                                    key={"DetailsList-" + props.IsMyProject + allProject.length + "-projectLatestResults:" + projectLatestResults + "-refreshIndex:" + refreshIndex}
                                    items={showProject}
                                    columns={newColumns}
                                    onRenderItemColumn={_renderItemColumn}
                                    ariaLabelForSelectionColumn="Toggle selection"
                                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                    checkButtonAriaLabel="select row"
                                    onColumnHeaderClick={_onColumnClick}
                                    onItemInvoked={_onItemInvoked}
                                    checkboxVisibility={CheckboxVisibility.always}
                                    selection={_selection}
                                    selectionMode={SelectionMode.multiple}
                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                    focusZoneProps={focusZoneProps}
                                    onRenderDetailsHeader={onRenderDetailsHeader}
                                    selectionZoneProps={{
                                        disableAutoSelectOnInputElements: true,
                                        isSelectedOnFocus: false,
                                        className: styles.selectionZone,
                                    }}
                                    columnReorderOptions={_getColumnReorderOptions()}
                                />
                            ) : (
                                <DetailsList
                                    styles={gridStylesGroup}
                                    compact={false}
                                    key={"DetailsList-Group-" + props.IsMyProject + allProject.length + "-projectLatestResults:" + projectLatestResults + "-refreshIndex:" + refreshIndex}
                                    items={showProject}
                                    columns={newColumns}
                                    groups={projectGroup}
                                    groupProps={{
                                        onRenderHeader,
                                        }}
                                    onRenderItemColumn={_renderItemColumn}
                                    ariaLabelForSelectionColumn="Toggle selection"
                                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                    checkButtonAriaLabel="select row"
                                    onItemInvoked={_onItemInvoked}
                                    checkboxVisibility={CheckboxVisibility.always}
                                    selection={_selection}
                                    selectionMode={SelectionMode.multiple}
                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                    focusZoneProps={focusZoneProps}
                                    onRenderDetailsHeader={onRenderDetailsHeader}
                                    selectionZoneProps={{
                                        disableAutoSelectOnInputElements: true,
                                        isSelectedOnFocus: false,
                                        className: styles.selectionZone,
                                    }}
                                    columnReorderOptions={_getColumnReorderOptions()}
                                />
                            )}
                        </div>
 :
                        <div className={styles.empty}>
                            <div>
                                <EmptyState />
                            </div>
                            <p>No current projects. Please add project to see data.</p>
                        </div>
                ) : <LoadingState />
            }
            
        </div>
    );
};

//Set group projects
function groupPlans(
    targetProjects: EFTrackerBigBetsProject[],
    projectLatestResults: EFTrackerBigBetsLatestByProject[],
): [IGroup[], EFTrackerBigBetsProject[]] {
    // create a Map
    const groupMap = new Map<string, EFTrackerBigBetsProject[]>();

    // Group filteredPlans according to the ProjectId
    targetProjects.map(project => {
        if (!groupMap.has(project.Category)) {
            groupMap.set(project.Category, []);
        }
        groupMap.get(project.Category)?.push(project);
    });

    let startIndex = 0;
    const newProjects: EFTrackerBigBetsProject[] = [];
    // Converts the packet information to IGroup[] format
    const groupedProjects: IGroup[] = Array.from(groupMap.entries()).map(([category, projects]) => {
        const tmpTotalImpact = projects.reduce((acc, item) => acc + (item.ImpactDollar || 0), 0);
        const tmpLatestImpact = projects.reduce((acc, item) => acc + (projectLatestResults.find(result => result.ProjectID === item.ProjectID)?.LatestActual || 0), 0);

        const formattedTotalImpact = currencyFormatter(tmpTotalImpact, 2, "$");
        const formattedLatestImpact = currencyFormatter(tmpLatestImpact, 2, "$");
        const CategoryTmp = category.padEnd(30, ' ');
        const group: IGroup = {
            key: category.toString(),
            name: category.toString(),
            startIndex: startIndex,
            count: projects.length,
            level: 0,
            isCollapsed: false,
            data: [CategoryTmp, formattedTotalImpact, formattedLatestImpact],
        };
        startIndex += projects.length; // accumulate startIndex
        newProjects.push(...projects);
        return group;
    });

    return [groupedProjects, newProjects];
}

export default BigBetsProjectTable;