import { LogComponent, LogElement } from "./LogModel";

export interface ITelemetryServiceCost {
    serviceId: string;
    serviceName: string;
    teamGroupId: string;
    teamGroupName: string;
    serviceGroupId: string;
    serviceGroupName: string;
    organizationId: string;
    organizationName: string;
    divisionId: string;
    divisionName: string;
    cosmosStorageCost: number;
    cosmosProcessingCost: number;
    blueShiftStorageCost: number;
    blueShiftProcessingCost: number;
    kustoCost: number;
    genevaMdsCost: number;
    genevaMdmCost: number;
    ariaCost: number;
    totalCost: number;
}

export interface ITelemetryResourceCost {
    timeStamp: moment.Moment;
    cosmosStorageCost: number;
    cosmosProcessingCost: number;
    blueShiftStorageCost: number;
    blueShiftProcessingCost: number;
    kustoCost: number;
    genevaMdsCost: number;
    genevaMdmCost: number;
    ariaCost: number;
}

export interface ITelemetryTotalCost {
    timeStamp: moment.Moment;
    organizationId: string;
    organizationName: string;
    cosmosStorageCost: number;
    cosmosProcessingCost: number;
    blueShiftStorageCost: number;
    blueShiftProcessingCost: number;
    kustoCost: number;
    genevaMdsCost: number;
    genevaMdmCost: number;
    ariaCost: number;
}

export interface ITelemetryTotalCost {
    timeStamp: moment.Moment;
    organizationId: string;
    organizationName: string;
    cosmosStorageCost: number;
    cosmosProcessingCost: number;
    blueShiftStorageCost: number;
    blueShiftProcessingCost: number;
    kustoCost: number;
    genevaMdsCost: number;
    genevaMdmCost: number;
    ariaCost: number;
}

export interface IPassiveMonitoringCost {
    timeStamp: moment.Moment;
    serviceId: string;
    serviceName: string;
    teamGroupId: string;
    teamGroupName: string;
    serviceGroupId: string;
    serviceGroupName: string;
    organizationId: string;
    organizationName: string;
    divisionId: string;
    divisionName: string;
    genevaMds: number;
    kusto: number;
    total: number;
}

export interface IPassiveMonitoringResourceCost {
    timeStamp: moment.Moment;
    genevaMds: number;
    kusto: number;
    total: number;
}

export interface IPassiveMonitoringCostTableCost {
    serviceId: string;
    serviceName: string;
    teamGroupId: string;
    teamGroupName: string;
    serviceGroupId: string;
    serviceGroupName: string;
    organizationId: string;
    organizationName: string;
    divisionId: string;
    divisionName: string;
    genevaMds: number;
    kusto: number;
    total: number;
}

export enum PassiveMonitoringEnum {
    All,
}

export enum PassiveMonitoringPlatformEnum {
    Kusto,
    GenevaMDS,
    All,
}

export const PassiveMonitoringPlatformName : Record<PassiveMonitoringPlatformEnum, string> = {
    [PassiveMonitoringPlatformEnum.All]: 'All',
    [PassiveMonitoringPlatformEnum.Kusto]: 'Kusto',
    [PassiveMonitoringPlatformEnum.GenevaMDS]: 'GenevaMDS',
};

export const PassiveMonitoringLogComponent: Record<PassiveMonitoringPlatformEnum, LogComponent> = {
    [PassiveMonitoringPlatformEnum.All]: LogComponent.TelemetryView,
    [PassiveMonitoringPlatformEnum.Kusto]: LogComponent.Kusto,
    [PassiveMonitoringPlatformEnum.GenevaMDS]: LogComponent.GenevaMDS,
};

export const PassiveMonitoringLogElement: Record<PassiveMonitoringPlatformEnum, LogElement> = {
    [PassiveMonitoringPlatformEnum.All]: LogElement.TelemetryTable,
    [PassiveMonitoringPlatformEnum.Kusto]: LogElement.KustoTable,
    [PassiveMonitoringPlatformEnum.GenevaMDS]: LogElement.GenevaMdsTable,
};

export enum TelemetrySourceTypeEnum {
    Cosmos,
    Blueshift,
    Kusto,
    GenevaMDS,
    GenevaMDM,
    Aria,
    All,
}

export enum TelemetrySourceTypeEnum2 {
    CosmosStorage,
    CosmosProcessing,
    BlueshiftStorage,
    BlueshiftProcessing,
    Kusto,
    GenevaMDS,
    GenevaMDM,
    Aria,
    All,
}

export const TelemetrySourceTypeName : Record<TelemetrySourceTypeEnum, string> = {
    [TelemetrySourceTypeEnum.All]: 'Telemetry',
    [TelemetrySourceTypeEnum.Cosmos]: 'Cosmos',
    [TelemetrySourceTypeEnum.Blueshift]: 'Blueshift',
    [TelemetrySourceTypeEnum.Kusto]: 'Kusto',
    [TelemetrySourceTypeEnum.GenevaMDS]: 'GenevaMDS',
    [TelemetrySourceTypeEnum.GenevaMDM]: 'GenevaMDM',
    [TelemetrySourceTypeEnum.Aria]: 'Aria',
};

export const TelemetrySourceTypeName2 : Record<TelemetrySourceTypeEnum2, string> = {
    [TelemetrySourceTypeEnum2.All]: 'Telemetry',
    [TelemetrySourceTypeEnum2.CosmosStorage]: 'Cosmos Storage',
    [TelemetrySourceTypeEnum2.CosmosProcessing]: 'Cosmos Processing',
    [TelemetrySourceTypeEnum2.BlueshiftStorage]: 'Blueshift Storage',
    [TelemetrySourceTypeEnum2.BlueshiftProcessing]: 'Blueshift Processing',
    [TelemetrySourceTypeEnum2.Kusto]: 'Kusto',
    [TelemetrySourceTypeEnum2.GenevaMDS]: 'GenevaMDS',
    [TelemetrySourceTypeEnum2.GenevaMDM]: 'GenevaMDM',
    [TelemetrySourceTypeEnum2.Aria]: 'Aria',
};

export const TelemetryLogComponent: Record<TelemetrySourceTypeEnum, LogComponent> = {
    [TelemetrySourceTypeEnum.All]: LogComponent.TelemetryView,
    [TelemetrySourceTypeEnum.Cosmos]: LogComponent.Cosmos,
    [TelemetrySourceTypeEnum.Blueshift]: LogComponent.Blueshift,
    [TelemetrySourceTypeEnum.Kusto]: LogComponent.Kusto,
    [TelemetrySourceTypeEnum.GenevaMDS]: LogComponent.GenevaMDS,
    [TelemetrySourceTypeEnum.GenevaMDM]: LogComponent.GenevaMDM,
    [TelemetrySourceTypeEnum.Aria]: LogComponent.Aria,
};

export const TelemetryLogComponent2: Record<TelemetrySourceTypeEnum2, LogComponent> = {
    [TelemetrySourceTypeEnum2.All]: LogComponent.TelemetryView,
    [TelemetrySourceTypeEnum2.CosmosStorage]: LogComponent.CosmosStorage,
    [TelemetrySourceTypeEnum2.CosmosProcessing]: LogComponent.CosmosProcessing,
    [TelemetrySourceTypeEnum2.BlueshiftStorage]: LogComponent.BlueshiftStorage,
    [TelemetrySourceTypeEnum2.BlueshiftProcessing]: LogComponent.BlueshiftProcessing,
    [TelemetrySourceTypeEnum2.Kusto]: LogComponent.Kusto,
    [TelemetrySourceTypeEnum2.GenevaMDS]: LogComponent.GenevaMDS,
    [TelemetrySourceTypeEnum2.GenevaMDM]: LogComponent.GenevaMDM,
    [TelemetrySourceTypeEnum2.Aria]: LogComponent.Aria,
};

export const TelemetryLogElement: Record<TelemetrySourceTypeEnum, LogElement> = {
    [TelemetrySourceTypeEnum.All]: LogElement.TelemetryTable,
    [TelemetrySourceTypeEnum.Cosmos]: LogElement.TelemetryCosmosTable,
    [TelemetrySourceTypeEnum.Blueshift]: LogElement.BlueshiftTable,
    [TelemetrySourceTypeEnum.Kusto]: LogElement.KustoTable,
    [TelemetrySourceTypeEnum.GenevaMDS]: LogElement.GenevaMdsTable,
    [TelemetrySourceTypeEnum.GenevaMDM]: LogElement.GenevaMdmTable,
    [TelemetrySourceTypeEnum.Aria]: LogElement.AriaTable,
};

export const TelemetryCostKeys: (TypedKeyOf<ITelemetryServiceCost, number>)[] = ['totalCost', 'cosmosStorageCost', 'cosmosProcessingCost', 'blueShiftStorageCost', 'blueShiftProcessingCost', 'kustoCost', 'genevaMdsCost', 'genevaMdmCost', 'ariaCost'];

export const TelemetryCostTableKeys: Record<TelemetrySourceTypeEnum, (keyof Partial<ITelemetryServiceCost>)[]> = {
    [TelemetrySourceTypeEnum.All]: ['serviceName', 'organizationName', 'serviceGroupName', 'totalCost', 'cosmosStorageCost', 'cosmosProcessingCost', 'blueShiftStorageCost', 'blueShiftProcessingCost', 'kustoCost', 'genevaMdsCost', 'genevaMdmCost', 'ariaCost'],
    [TelemetrySourceTypeEnum.Cosmos]: ['serviceName', 'organizationName', 'serviceGroupName', 'totalCost', 'cosmosStorageCost', 'cosmosProcessingCost'],
    [TelemetrySourceTypeEnum.Blueshift]: ['serviceName', 'organizationName', 'serviceGroupName', 'totalCost', 'blueShiftStorageCost', 'blueShiftProcessingCost'],
    [TelemetrySourceTypeEnum.Kusto]: ['serviceName', 'organizationName', 'serviceGroupName', 'totalCost', 'kustoCost'],
    [TelemetrySourceTypeEnum.GenevaMDS]: ['serviceName', 'organizationName', 'serviceGroupName', 'totalCost', 'genevaMdsCost'],
    [TelemetrySourceTypeEnum.GenevaMDM]: ['serviceName', 'organizationName', 'serviceGroupName', 'totalCost', 'genevaMdmCost'],
    [TelemetrySourceTypeEnum.Aria]: ['serviceName', 'organizationName', 'serviceGroupName', 'totalCost', 'ariaCost'],
};

export const PassiveMonitoringCostKeys: (TypedKeyOf<IPassiveMonitoringCost, number>)[] = ['total', 'genevaMds', 'kusto'];

export const PassiveMonitoringCostTableKeys: Record<PassiveMonitoringPlatformEnum, (keyof Partial<IPassiveMonitoringCost>)[]> = {
    [PassiveMonitoringPlatformEnum.All]: ['serviceName', 'organizationName', 'serviceGroupName', 'total', 'genevaMds', 'kusto'],
    [PassiveMonitoringPlatformEnum.Kusto]: ['serviceName', 'organizationName', 'serviceGroupName', 'total', 'kusto'],
    [PassiveMonitoringPlatformEnum.GenevaMDS]: ['serviceName', 'organizationName', 'serviceGroupName', 'total', 'genevaMds'],
};

export const TelemetryCostDisplayNames : Record<keyof ITelemetryServiceCost, string> = {
    serviceId: 'Service Id',
    serviceName: 'Service Name',
    teamGroupId: 'Team Group Id',
    teamGroupName: 'Team Group Name',
    serviceGroupId: 'Service Group Id',
    serviceGroupName: 'Service Group Name',
    organizationId: 'OrganizationId',
    organizationName: 'OrganizationName',
    divisionId: 'DivisionId',
    divisionName: 'DivisionName',
    cosmosStorageCost: 'Cosmos Storage Cost',
    cosmosProcessingCost: 'Cosmos Processing Cost',
    blueShiftStorageCost: 'Blueshift Storage Cost',
    blueShiftProcessingCost: 'Blueshift Processing Cost',
    kustoCost: 'Kusto Cost',
    genevaMdsCost: 'Geneva MDS Cost',
    genevaMdmCost: 'Geneva MDM Cost',
    ariaCost: 'Aria Cost',
    totalCost: 'Total Cost'
};

export const PassiveMonitoringCostDisplayNames : Record<keyof IPassiveMonitoringCost, string> = {
    timeStamp: 'Time',
    serviceId: 'Service Id',
    serviceName: 'Service Name',
    teamGroupId: 'Team Group Id',
    teamGroupName: 'Team Group Name',
    serviceGroupId: 'Service Group Id',
    serviceGroupName: 'Service Group Name',
    organizationId: 'OrganizationId',
    organizationName: 'OrganizationName',
    divisionId: 'DivisionId',
    divisionName: 'DivisionName',
    kusto: 'Kusto Cost',
    genevaMds: 'Geneva MDS Cost',
    total: 'Total Cost',
};