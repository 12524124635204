import { IPassiveMonitoringCost, IPassiveMonitoringCostTableCost, ITelemetryResourceCost, ITelemetryServiceCost, ITelemetryTotalCost, PassiveMonitoringCostKeys, TelemetryCostKeys } from "../models/TelemetryCogsModel";
import moment, { Moment } from "moment";

import { CategoryDivision } from "../models/CategoryDivision";
import { ServiceTreeItem } from "../models/serviceTree";
import { parseFiltersToJson } from "../models/FilterView";
import { postJson } from "../utils/apiServiceBase";

export const getTelemetryServiceCostSummary = (data: ITelemetryServiceCost[]) : Record<TypedKeyOf<ITelemetryServiceCost, number>, number> => {
    const result : Record<TypedKeyOf<ITelemetryServiceCost, number>, number> = {
        totalCost: 0,
        cosmosStorageCost: 0,
        cosmosProcessingCost: 0,
        blueShiftStorageCost: 0,
        blueShiftProcessingCost: 0,
        kustoCost: 0,
        genevaMdsCost: 0,
        genevaMdmCost: 0,
        ariaCost: 0,
    };

    data.forEach((item) => {
        TelemetryCostKeys.forEach((key) => {
            result[key] += (item[key] || 0);
        });
    });

    return result;
};

export const getPassiveMonitoringCostSummary = (data: IPassiveMonitoringCostTableCost[]) : Record<TypedKeyOf<IPassiveMonitoringCostTableCost, number>, number> => {
    const result : Record<TypedKeyOf<IPassiveMonitoringCostTableCost, number>, number> = {
        total: 0,
        kusto: 0,
        genevaMds: 0,
    };

    data.forEach((item) => {
        PassiveMonitoringCostKeys.forEach((key) => {
            result[key] += (item[key] || 0);
        });
    });

    return result;
};

export async function getServiceTelemetryCogs({ queryKey }: { queryKey: [_: string, filters: Partial<Record<CategoryDivision, string[]>>, serviceIdMap: Map<string, ServiceTreeItem>, start: Moment, end: Moment, limit: number] }) : Promise<ITelemetryServiceCost[]> {
    const [, filters, serviceIdMap, start, end, limit] = queryKey;

    const url = `api/telemetryCogs/serviceTelemetryCogs?startDate=${start.format('YYYY-MM-DD')}&endDate=${end.format('YYYY-MM-DD')}&limit=15000`;

    const data: ITelemetryServiceCost[] = await postJson(url, parseFiltersToJson(filters, serviceIdMap));

    data.forEach(item => {
        item.totalCost = (item.cosmosStorageCost || 0) + (item.cosmosProcessingCost || 0) + (item.blueShiftStorageCost || 0) + (item.blueShiftProcessingCost || 0) + (item.kustoCost || 0)
        + (item.genevaMdsCost || 0) + (item.genevaMdmCost || 0) + (item.ariaCost || 0);
    });

    return data;
}

export async function getResourceTelemetryCogs({ queryKey }: { queryKey: [_: string, filters: Partial<Record<CategoryDivision, string[]>>, serviceIdMap: Map<string, ServiceTreeItem>, start: Moment, end: Moment] }) : Promise<ITelemetryResourceCost[]> {
    const [, filters, serviceIdMap, start, end] = queryKey;

    const url = `api/telemetryCogs/resourceTelemetryCogs?startDate=${start.format('YYYY-MM-DD')}&endDate=${end.format('YYYY-MM-DD')}`;

    const data: ITelemetryResourceCost[] = await postJson(url, parseFiltersToJson(filters, serviceIdMap));

    return data;
}

export async function getTotalTelemetryCogs({ queryKey }: { queryKey: [_: string, filters: Partial<Record<CategoryDivision, string[]>>, serviceIdMap: Map<string, ServiceTreeItem>, start: Moment, end: Moment] }) : Promise<ITelemetryTotalCost[]> {
    const [, filters, serviceIdMap, start, end] = queryKey;

    const url = `api/telemetryCogs/telemetryCogsSummary?startDate=${start.format('YYYY-MM-DD')}&endDate=${end.format('YYYY-MM-DD')}`;

    const data: ITelemetryTotalCost[] = await postJson(url, parseFiltersToJson(filters, serviceIdMap));

    return data;
}

export async function getPassvieMonitoringCogs({ queryKey }: { queryKey: [_: string, filters: Partial<Record<CategoryDivision, string[]>>, serviceIdMap: Map<string, ServiceTreeItem>, start: Moment, end: Moment] }) : Promise<IPassiveMonitoringCost[]> {
    const [, filters, serviceIdMap, start, end] = queryKey;

    const url = `api/telemetryCogs/telemetryCogsPassiveMonitoring?startDate=${start.format('YYYY-MM-DD')}&endDate=${end.format('YYYY-MM-DD')}`;

    const data: IPassiveMonitoringCost[] = await postJson(url, parseFiltersToJson(filters, serviceIdMap));

    data.forEach(item => {
        item.total = (item.kusto || 0) + (item.genevaMds || 0);
    });

    return data;
}