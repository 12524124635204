import { PassiveMonitoringPlatformEnum, PassiveMonitoringPlatformName } from "../../../models/TelemetryCogsModel";
import { Stack, StackItem } from "@fluentui/react";

import PassiveMonitoringTrendChart from "./PassiveMonitoringTrendChart";
import React from "react";
import commonStyles from "../../common/styles/Common.less";
import styles from "./TelemetryChart.less";
import { useCategoryFilters } from "../../../hooks/useFilters";
import { values } from "lodash";

interface IPassiveMonitoringChartProps {
    type: PassiveMonitoringPlatformEnum;
}

const PassiveMonitoringChart: React.FC<IPassiveMonitoringChartProps> = (props) => {
    const globalFiltersData = useCategoryFilters().filters;

    return (
        <div className={commonStyles.gridWrapper}>
            <div className={commonStyles.gridColumn2}>
                <PassiveMonitoringTrendChart sources={[PassiveMonitoringPlatformEnum.Kusto]} title={`${PassiveMonitoringPlatformName[PassiveMonitoringPlatformEnum.Kusto]} Cost Trends`} />
                <PassiveMonitoringTrendChart sources={[PassiveMonitoringPlatformEnum.GenevaMDS]} title={`${PassiveMonitoringPlatformName[PassiveMonitoringPlatformEnum.GenevaMDS]} Cost Trends`} />
            </div>
        </div>
    );
};

export default PassiveMonitoringChart;
