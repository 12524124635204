/**Render the main page to create Efficiency Project, major sub-components:
 * 1. Show Steps
 * 2. Show Forms based on steps
 * 3. User controlled buttons like next, back, done
*/
import { IEfficiencyProjectForm, CopsResourceTypeEnum, ComponentTypeEnum, Component, IErrorDict, IEfficiencyTrackerProject, EfficiencyTrackerReductionPlanRegionConfig, PlatformTypeEnum, TargetPair, AddProjectTypeEnum, EfficiencyTrackerReductionPlan } from '../../../../models/EfficiencyTracker';
import React, { useEffect, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import styles from "./AddProjectView.less";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Breadcrumb, IBreadcrumbItem, Stack } from '@fluentui/react';


import NewProjectInfo from './Forms/NewProjectInfo';
import ResourceInfo from './Forms/ResourceInfo';
import ReductionPlan from './Forms/ReductionPlan';
import Confirmation from './Forms/Confirmation';
import { useImmer } from 'use-immer';
import { postMethod } from '../../../../utils/apiServiceBase';
import { useMsal } from "@azure/msal-react";
import { useGotoPage } from '../../../../hooks/useGotoPage';
import { useLocation } from 'react-router-dom';
import { Pages } from '../../../../models/Nav';
import ResourceInfoBaremetal from './Forms/ResourceInfoBaremetal';
import { EfficiencyTargetCategoryEnum } from '../../../../models/EfficiencyTracker';
import { useIEfficiencyTrackerProject } from '../../../../hooks/useEfficiencyTrackerProject';
import { useQueryClient } from 'react-query';


/** Helper function to create list of components from resources info*/
export function resourceInfoToComponent(resourceInfo: IEfficiencyProjectForm): Component[] {
    const components: Component[] = [];
    for (const serviceID of resourceInfo.ServiceID) {
        components.push({ Type: ComponentTypeEnum.ServiceId, Value: serviceID });
    }
    for (const subscriptionId of resourceInfo.SubscriptionId) {
        components.push({ Type: ComponentTypeEnum.SubscriptionId, Value: subscriptionId });
    }
    components.push(...resourceInfo.RestApp);
    components.push(...resourceInfo.Process);
    components.push(...resourceInfo.Shard);
    components.push(...resourceInfo.Forest);
    components.push(...resourceInfo.Dag);
    components.push(...resourceInfo.DagConfiguration);
    components.push(...resourceInfo.Sku);

    return components;
}

interface IAddProjectView {
    mode: AddProjectTypeEnum;
}

const AddProjectView: React.FC<IAddProjectView> = (props) => {
    //breadcrumb
    const gotoPage = useGotoPage();
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const projectlink = pathSegments[2];
    const isBaremetal = pathSegments[3];
    const uriId = parseInt(pathSegments[5], 10); //If addPlans is ProjectId, else is planId

    const getBreadcrumbItems = () => {
        const breadcrumbItems: IBreadcrumbItem[] = [
            { key: 'Project', text: projectlink, onClick: () => gotoPage(`${Pages.EfficiencyTrackerV2}/${projectlink}/${isBaremetal}`) },
            { key: 'isBaremetal', text: isBaremetal, onClick: () => gotoPage(`${Pages.EfficiencyTrackerV2}/${projectlink}/${isBaremetal}`) },
        ];
        switch (props.mode) {
            case AddProjectTypeEnum.AddProject:
                breadcrumbItems.push({ key: 'AddProject', text: "Add Project" });
                break;
            case AddProjectTypeEnum.EditProject:
                breadcrumbItems.push({ key: 'EditProject', text: "Edit Project" });
                break;
            case AddProjectTypeEnum.AddPlan:
                breadcrumbItems.push({ key: 'AddPlan', text: "Add Plan" });
                break;
        }
        return breadcrumbItems;
    }

    //PlatformFlag
    const isBaremetalFlag = isBaremetal === Pages.BaremetalTracker.toString() ? true : false;

    // Get the current logged in user account
    const { instance } = useMsal();
    const account = instance.getActiveAccount();

    // Name of the steps to create a new efficiency project
    const steps = [
        "Project info",
        "Plan info: Select resource",
        "Plan info: Assign regions and periods",
        "Review and finish"
    ];

    // State to keep track of the current step
    const [activeStep, setActiveStep] = useState(0);

    // Get all projects
    const queryClient = useQueryClient();
    const { plans: plans, project: project, isLoading } = useIEfficiencyTrackerProject();

    // State to keep track of the form data,
    // include all information needed to create a new efficiency project
    const [formData, updateFormData] = useImmer<IEfficiencyProjectForm>(() => {
        const defaultFormData: IEfficiencyProjectForm = {
            ProjectID: 0,
            PlanID: 0,
            Owner: "",
            ProjectName: "",
            ConfidenceLevel: "",
            Description: "",
            ResourceType: isBaremetalFlag ? CopsResourceTypeEnum.IO : CopsResourceTypeEnum.VCore,
            Platform: isBaremetalFlag ? PlatformTypeEnum.Baremetal : undefined,
            EfficiencyTargetCatagory: EfficiencyTargetCategoryEnum.Demands,
            ServiceID: [],
            SubscriptionId: [],
            Process: [],
            RestApp: [],
            Shard: [],
            Forest: [],
            Dag: [],
            DagConfiguration: [],
            Sku: [],
            BaseLineTime: new Date(),
            EffectivePeriod: undefined,
            RegionalTargets: [],
            TrackingGap: 0,
        };

        return defaultFormData;
    });

    // Loading formdata when plan&project are ready
    useEffect(() => {
        if (isLoading) return;
        if (props.mode === AddProjectTypeEnum.EditProject) {
            const thisPlan = plans.find(item => item.Id === uriId);
            const thisProject = project.find(item => item.Id === thisPlan?.ProjectId);
            if (!thisPlan || !thisProject) {
                // This should never happen
                console.error("Unable to load plan with id: ", uriId);
                return;
            }

            // If in edit mode, get the plan id from the url, initialize the form with the project data
            updateFormData(formData => {
                formData.ProjectID = thisProject.Id;
                formData.PlanID = thisPlan.Id;
                formData.Owner = thisProject.Owner;
                formData.ProjectName = thisProject.Name;
                formData.ConfidenceLevel = thisPlan.ConfidenceLevel;
                formData.Description = thisProject.Description;
                formData.ResourceType = thisPlan.ResourceType;
                formData.Platform = thisPlan.Platform;
                formData.EfficiencyTargetCatagory = thisPlan.EfficiencyTargetCatagory as EfficiencyTargetCategoryEnum;
                formData.TrackingGap = thisPlan.TrackingGap;
                for (const component of thisPlan.Components as unknown as Component[]) {
                    switch (component.Type) {
                        case ComponentTypeEnum.ServiceId:
                            formData.ServiceID.push(component.Value);
                            break;
                        case ComponentTypeEnum.SubscriptionId:
                            formData.SubscriptionId.push(component.Value);
                            break;
                        case ComponentTypeEnum.Process:
                            formData.Process.push(component);
                            break;
                        case ComponentTypeEnum.RestApp:
                            formData.RestApp.push(component);
                            break;
                        case ComponentTypeEnum.Shard:
                            formData.Shard.push(component);
                            break;
                        case ComponentTypeEnum.Forest:
                            formData.Forest.push(component);
                            break;
                        case ComponentTypeEnum.Dag:
                            formData.Dag.push(component);
                            break;
                        case ComponentTypeEnum.DagConfiguration:
                            formData.DagConfiguration.push(component);
                            break;
                        case ComponentTypeEnum.Sku:
                            formData.Sku.push(component);
                            break;
                        default:
                            console.error("Unknown component type: ", component.Type);
                    }
                }
                formData.BaseLineTime = new Date(thisProject.CreateTime);
                formData.EffectivePeriod = ((thisPlan.EfficiencyTrackerReductionPlanRegionConfigs as unknown as EfficiencyTrackerReductionPlanRegionConfig[])[0].Targets as unknown as TargetPair[]).length;

                formData.RegionalTargets = (thisPlan.EfficiencyTrackerReductionPlanRegionConfigs as unknown as EfficiencyTrackerReductionPlanRegionConfig[]).map(regionConfig => {
                    return {
                        Region: regionConfig.Region || regionConfig.AzureRegion || "Unknown",
                        BaseLineValue: regionConfig.BaseLineValue,
                        TotalTarget: (regionConfig.Targets as unknown as TargetPair[]).reduce((acc, target) => acc + target.Value, 0),
                        Targets: regionConfig.Targets as unknown as TargetPair[],
                    }
                });
            });
        } else if (props.mode === AddProjectTypeEnum.AddPlan) {
            const thisProject = project.find(item => item.Id === uriId);
            if (!thisProject) {
                // This should never happen
                console.error("Unable to load plan with id: ", uriId);
                return;
            }

            // If in AddPlan mode, get the project id from the url, initialize the form with the project data
            updateFormData(formData => {
                formData.ProjectID = thisProject.Id;
                formData.Owner = thisProject.Owner;
                formData.ProjectName = thisProject.Name;
                formData.Description = thisProject.Description;
                formData.BaseLineTime = new Date(thisProject.CreateTime);
            });
        }
    }, [isLoading, props.mode, uriId]);

    // State to keep track of the error messages
    const [errorDict, updateErrorDict] = useImmer<IErrorDict>({});

    const [isSubmitting, { setTrue: submitting, setFalse: submitDone }] = useBoolean(false);

    const handleNext = () => {
        const errors = validateForm();
        if (Object.keys(errors).length !== 0) {
            updateErrorDict(errors);
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleDone = () => {
        gotoPage(`${Pages.EfficiencyTrackerV2}/${projectlink}/${isBaremetal}`)
    };

    const handleSubmit = () => {
        if (!account) {
            updateErrorDict(errorDict => {
                errorDict.SubmitError = "Please login!";
            });
            return;
        }

        // Map formData to backend schema:
        // 1. Map regional targets
        const EfficiencyTrackerReductionPlanRegionConfigs: EfficiencyTrackerReductionPlanRegionConfig[] = formData.RegionalTargets.map(regionTarget => {
            const effectivePeriodDate = new Date(formData.BaseLineTime);
            effectivePeriodDate.setMonth(effectivePeriodDate.getMonth() + regionTarget.Targets.length);
            const config: EfficiencyTrackerReductionPlanRegionConfig = {
                Targets: JSON.stringify(regionTarget.Targets),
                BaseLineTime: formData.BaseLineTime,
                BaseLineValue: regionTarget.BaseLineValue,
                EffectivePeriod: effectivePeriodDate,
            }
            if (isBaremetalFlag) {
                config.Region = regionTarget.Region
            } else {
                config.AzureRegion = regionTarget.Region
            }
            return config;
        });

        // 2. Map components
        const Components: Component[] = resourceInfoToComponent(formData);

        // 3. Map everything to backend schema
        const iEfficiencyTrackerProject: IEfficiencyTrackerProject = {
            EfficiencyTrackerProject: {
                Id: formData.ProjectID,
                Name: formData.ProjectName,
                Owner: formData.Owner || account.username,
                CreateTime: formData.BaseLineTime,
                Description: formData.Description,
            },
            EfficiencyTrackerReductionPlans: [{
                Id: formData.PlanID,
                ProjectId: formData.ProjectID,
                Target: "Target",
                EfficiencyTrackerReductionPlanRegionConfigs: JSON.stringify(EfficiencyTrackerReductionPlanRegionConfigs),
                Components: JSON.stringify(Components),
                Status: "Approved",
                ConfidenceLevel: formData.ConfidenceLevel,
                QualityForMap: "Yes",
                Platform: formData.Platform!,
                Owner: formData.Owner || account.username,
                CreateTime: props.mode === AddProjectTypeEnum.AddPlan ? new Date() : formData.BaseLineTime,
                ResourceType: formData.ResourceType,
                EfficiencyTargetCatagory: formData.EfficiencyTargetCatagory,
                TrackingGap: formData.EfficiencyTargetCatagory === EfficiencyTargetCategoryEnum.Capacity ? formData.TrackingGap : 0,
            }]
        };

        // Save to backend
        submitting();
        const postURL = props.mode === AddProjectTypeEnum.AddPlan
            ? 'api/efficiencytracker/addplan'
            : props.mode === AddProjectTypeEnum.EditProject
                ? 'api/efficiencytracker/edit'
                : 'api/efficiencytracker/add';

        const postData = props.mode === AddProjectTypeEnum.AddPlan
            ? iEfficiencyTrackerProject.EfficiencyTrackerReductionPlans[0]
            : iEfficiencyTrackerProject;

        handlePostRequest(postURL, postData);
    };

    const handlePostRequest = async (postURL: string, postData: any) => {
        try {
            const response = await postMethod(postURL, postData);
            const responseData = await response.text();
            if (response.ok) {
                updateErrorDict((errorDict) => {
                    if ("SubmitError" in errorDict) {
                        delete errorDict.SubmitError;
                    }
                });
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                //Invalidate cache
                queryClient.invalidateQueries(["EfficiencyTrackerService::getAllProjects"])
            } else {
                updateErrorDict((errorDict) => {
                    errorDict.SubmitError = responseData || "New request upload failed! Please try again later";
                });
            }
        } catch (error) {
            updateErrorDict((errorDict) => {
                errorDict.SubmitError = 'Error when uploading New request! ' + error;
            });
        } finally {
            submitDone();
        }
    };

    function validateForm(): IErrorDict {
        const errors: IErrorDict = {};
        switch (activeStep) {
            // Validation for Basic project info
            case 0:
                if (!formData.ProjectName) {
                    errors.ProjectNameError = "Project name can not be empty!";
                }
                if (formData.ProjectName.length > 256) {
                    errors.ProjectNameError = "Project name contains a maximum of 256 characters!";
                }
                if (!formData.ConfidenceLevel) {
                    errors.ConfidenceLevelError = "Confidence level not selected!";
                }

                if (formData.Description.length > 256) {
                    errors.DescriptionError = "Description contains a maximum of 256 characters!";
                }
                break;
            // Validation for Resource info
            case 1:
                if (formData.Platform === PlatformTypeEnum.Baremetal) {
                    switch (formData.ResourceType as CopsResourceTypeEnum) {
                        case CopsResourceTypeEnum.IO:
                            if (formData.RestApp.length === 0) {
                                errors.RestAppComponentError = 'Please at least select one REST Application Name!';
                            }
                            break;
                        case CopsResourceTypeEnum.CPU:
                            if (formData.RestApp.length + formData.Process.length === 0) {
                                errors.RestAppComponentError = 'Please at least select one component in Process or REST Application Name!';
                                errors.ProcComponentError = 'Please at least select one component in Process or REST Application Name!';
                            }
                            break;
                        case CopsResourceTypeEnum.Memory:
                            if (formData.Process.length === 0) {
                                errors.ProcComponentError = 'Please at least select one component in Process Name!';
                            }
                            break;
                        case CopsResourceTypeEnum.HDD:
                        case CopsResourceTypeEnum.SSD:
                            if (formData.Shard.length === 0) {
                                errors.ShardComponentError = 'Please select one or more Shards!';
                            }
                        default:
                            break;
                    }
                }
                else {
                    if (!formData.Platform) {
                        errors.PlatformChoiceError = "Platform not selected!";
                    }
                    if (formData.ServiceID.length === 0 && formData.SubscriptionId.length === 0) {
                        errors.ServiceSubscriptionIdError = "Please enter at least one service ID or subscription ID!";
                    }
                }
                break;
            // Validation for Reduction plan
            case 2:
                if (formData.RegionalTargets.length === 0) {
                    errors.AddRegionalPlanError = "Please create plans!"
                }
                break;
            default:
                break;
        }
        return errors;
    }

    /** Clear regional targets when resource identifiers changed */
    useEffect(() => {
        if (props.mode !== AddProjectTypeEnum.EditProject) {
            updateFormData(formData => {
                formData.RegionalTargets = [];
            });
        }
    }, [formData.ResourceType, formData.Platform, formData.ServiceID, formData.SubscriptionId,
    formData.RestApp, formData.Process, formData.Shard, formData.Forest, formData.Dag, formData.DagConfiguration, formData.Sku])

    /** Clear resource identifiers when platform changed */
    useEffect(() => {
        if (props.mode !== AddProjectTypeEnum.EditProject) {
            updateFormData(formData => {
                formData.ServiceID = [];
                formData.SubscriptionId = [];
            });
        }
    }, [formData.Platform])

    const curForm = () => {
        switch (activeStep) {
            case 0:
                return <NewProjectInfo
                    formData={formData}
                    updateFormData={updateFormData}
                    errorDict={errorDict}
                    updateErrorDict={updateErrorDict}
                    mode={props.mode}
                />;
            case 1:
                return formData.Platform === PlatformTypeEnum.Baremetal ?
                    <ResourceInfoBaremetal
                        formData={formData}
                        updateFormData={updateFormData}
                        errorDict={errorDict}
                        updateErrorDict={updateErrorDict}
                        editMode={props.mode === AddProjectTypeEnum.EditProject}
                    /> :
                    <ResourceInfo
                        formData={formData}
                        updateFormData={updateFormData}
                        errorDict={errorDict}
                        updateErrorDict={updateErrorDict}
                        editMode={props.mode === AddProjectTypeEnum.EditProject}
                    />;
            case 2:
                return <ReductionPlan
                    formData={formData}
                    updateFormData={updateFormData}
                    errorDict={errorDict}
                    updateErrorDict={updateErrorDict}
                    mode={props.mode}
                />;
            case 3:
                return <Confirmation
                    formData={formData}
                    setActiveStep={setActiveStep}
                    errorDict={errorDict}
                    confirmed={false}
                    editMode={props.mode === AddProjectTypeEnum.EditProject}
                />;
            case 4:
                return <Confirmation
                    formData={formData}
                    setActiveStep={setActiveStep}
                    errorDict={errorDict}
                    confirmed={true}
                    editMode={props.mode === AddProjectTypeEnum.EditProject}
                />;
            default:
                return <></>;
        }
    }


    return (
        <div className={styles.container}>

            <div className={styles.breadcrumbContainer}>
                <Breadcrumb items={getBreadcrumbItems()} />
            </div>

            <div className={styles.splitPanes}>

                <div className={styles.stepsContainer}>
                    <Box sx={{ maxWidth: 280 }}>
                        <Stepper activeStep={activeStep}
                            orientation="vertical"
                            // Turn stepper to green when all done
                            sx={activeStep === steps.length ? {
                                '& .MuiStepIcon-root': { color: '#00AA00' }
                            } : {}}>
                            {steps.map((stepText, index) => (
                                <Step key={stepText}>
                                    <StepLabel> {stepText} </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </div>

                <div className={styles.formContainer}>
                    {curForm()}
                </div>

            </div>

            <div className={styles.bottomContainer}>
                {activeStep === steps.length ? (
                    <PrimaryButton
                        text="Done"
                        onClick={handleDone}
                    />

                ) : (
                    <>
                        <Stack horizontal tokens={{ childrenGap: 16 }}>
                            <DefaultButton
                                text="Back"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                            />
                            {activeStep === steps.length - 1 ? (
                                <PrimaryButton
                                    text={`Confirm and submit ${props.mode === AddProjectTypeEnum.EditProject ? "changes" : ""}`}
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                />
                            ) : (
                                <PrimaryButton
                                    text="Next"
                                    onClick={handleNext}
                                />
                            )}
                        </Stack>
                        <DefaultButton
                            text="Close"
                            onClick={handleDone}
                            styles={{ root: { marginLeft: 'auto' } }}
                        />
                    </>
                )}

            </div>

        </div>
    );

}

export default AddProjectView;