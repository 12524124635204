import { Cell, Column, HeaderGroup, Row, TableInstance, TableOptions, UseSortByInstanceProps, UseSortByOptions, useSortBy, useTable } from 'react-table';
import { FilterList, FilterType, IFilterItem } from '../filter/filterList/FilterList';
import { FiltersAction, FiltersView } from '../../reducers/filterReducer';
import { IIconProps, Icon, Link } from '@fluentui/react';
import { IPassiveMonitoringCost, IPassiveMonitoringCostTableCost, PassiveMonitoringCostDisplayNames, PassiveMonitoringCostKeys, PassiveMonitoringCostTableKeys, PassiveMonitoringPlatformEnum } from '../../models/TelemetryCogsModel';
import { IServiceTreeData, getCategoryByServiceTreeLevel } from '../../reducers/serviceTreeReducer';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ServiceTree, ServiceTreeItem, ServiceTreeLevel } from '../../models/serviceTree';
import StickyTable, { IStickyTableCellOption, IStickyTableFooterOption, IStickyTableHeaderOption } from '../common/table/StickyTable';
import { useDispatch, useSelector } from 'react-redux';

import { ActionButton } from '@fluentui/react';
import { IAppState } from '../../store';
import { SectionHeader } from '../common/SectionHeader';
import { exportToCsv } from '../../utils/common';
import { getPassiveMonitoringCostSummary } from '../../services/TelemetryCogsService'
import { match } from '../filter/filterList/FilterUtils';
import styles from './PassiveMonitoringCostTable.less';
import { useCategoryFilters } from '../../hooks/useFilters';
import { useGetPassiveMonitoringCost } from '../../hooks/useTelemetryCost';

export interface IPassiveMonitoringCostTableProps {
    type: PassiveMonitoringPlatformEnum;
}

const feedbackIconProps: IIconProps = { iconName: "Feedback" };

const getCellOptions = (cell: Cell) : IStickyTableCellOption => {
    return {
        props: {
            key: cell.column.id,
            className: PassiveMonitoringCostKeys.find((item) => item === cell.column.id) ? styles.textAlignRight : '',
        }
    }
};

const getHeaderOption = (header: HeaderGroup): IStickyTableHeaderOption => {
    return {
        props: {
            key: header.id,
            className: PassiveMonitoringCostKeys.find((item) => item === header.id) ? styles.textAlignRight : '',
        }
    }
};

const getFooterOption = (footer: HeaderGroup): IStickyTableFooterOption => {
    return {
        props: {
            key: footer.id,
            className: PassiveMonitoringCostKeys.find((item) => item === footer.id) ? styles.textAlignRight : '',
        }
    }
};

const getDefaultSortColumn = (type: PassiveMonitoringPlatformEnum) : keyof IPassiveMonitoringCostTableCost => {
    if (type === PassiveMonitoringPlatformEnum.All) return 'total';
    if (type === PassiveMonitoringPlatformEnum.Kusto) return 'kusto';
    if (type === PassiveMonitoringPlatformEnum.GenevaMDS) return 'genevaMds';

    return 'total';
};

const intlFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
});

const filterOptions: Record<PassiveMonitoringPlatformEnum, (keyof IPassiveMonitoringCostTableCost)[]> = {
    [PassiveMonitoringPlatformEnum.All]: ['total'],
    [PassiveMonitoringPlatformEnum.Kusto]: ['kusto'],
    [PassiveMonitoringPlatformEnum.GenevaMDS]: ['genevaMds'],
}

export const PassiveMonitoringTableDefaultPageSize = 50;

const PassiveMonitoringCostTable: React.FC<IPassiveMonitoringCostTableProps> = (props) => {
    const [filters, setFilters] = useState<IFilterItem[]>([]);
    const query = useGetPassiveMonitoringCost();
    const [data, setData] = useState<IPassiveMonitoringCostTableCost[] | undefined>(query.data);
    const [isLoading, setLoading] = useState(query.isLoading);
    const [filteredTotal, setFilteredTotal] = useState<Record<TypedKeyOf<IPassiveMonitoringCostTableCost, number>, number>>();
    const [filteredData, setFilteredData] = useState<IPassiveMonitoringCostTableCost[]>(query.data || []);
    const serviceTreeData = useSelector<IAppState, IServiceTreeData>(state => state.serviceTree);
    const updateFilters = useCategoryFilters().updateFilters;

    const summarizedData = query.data?.reduce<Record<string, IPassiveMonitoringCostTableCost>>((acc, item) => {
        const key = `${item.serviceId}-${item.serviceName}-${item.teamGroupId}-${item.teamGroupName}-${item.serviceGroupId}-${item.serviceGroupName}-${item.organizationId}-${item.organizationName}-${item.divisionId}-${item.divisionName}`;
        if (!acc[key]) {
            acc[key] = {
              serviceId: item.serviceId,
              serviceName: item.serviceName,
              teamGroupId: item.teamGroupId,
              teamGroupName: item.teamGroupName,
              serviceGroupId: item.serviceGroupId,
              serviceGroupName: item.serviceGroupName,
              organizationId: item.organizationId,
              organizationName: item.organizationName,
              divisionId: item.divisionId,
              divisionName: item.divisionName,
              genevaMds: 0,
              kusto: 0,
              total: 0,
            };
          }
          acc[key].genevaMds += item.genevaMds;
          acc[key].kusto += item.kusto;
          acc[key].total += item.total;
          return acc;
    }, {});
        
    const result: IPassiveMonitoringCostTableCost[] = Object.values(summarizedData || []);

    useEffect(() => {
        if (!query.data) return;

        const newData = result?.filter(item => item.total > 0).filter(item => !filters.find(filter => !match(item[filter.key as TypedKeyOf<IPassiveMonitoringCostTableCost, number>], filter.operator, parseFloat(filter.value || "0")))).sort((a, b) => b.total - a.total). slice(0,50) || [];
        const newTotal = getPassiveMonitoringCostSummary(newData);

        setFilteredData(newData);
        setFilteredTotal(newTotal);
    }, [query.data, filters]);

    const handleDownload = () => {
        exportToCsv(query.data || [], 'Passive Monitoring Cost', PassiveMonitoringCostTableKeys[props.type], PassiveMonitoringCostDisplayNames, (key: string, value: string | number) => {
            if (value === undefined || value === null) return '-';

            return String(value);
        });
    };

    const handleLinkToServiceTree = useCallback((orgId?: string) => {
        if (!orgId) return;
        const serviceTreeNode = serviceTreeData.indexMap.get(orgId);
        if (!serviceTreeNode) return;

        if (serviceTreeNode.l === ServiceTreeLevel.Organization) {
            updateFilters(FiltersAction.Replace, {filters: {[getCategoryByServiceTreeLevel(serviceTreeNode.l)]: [serviceTreeNode.id]}, view: FiltersView.Breadcrumb});
        } else if (serviceTreeNode.l === ServiceTreeLevel.ServiceGroup) {
            updateFilters(FiltersAction.Replace, {filters: {[getCategoryByServiceTreeLevel(serviceTreeNode.l)]: [serviceTreeNode.id]}, view: FiltersView.Breadcrumb});
        }
    }, [serviceTreeData.indexMap, updateFilters]);

    const renderCell = useCallback((cell: Cell) =>  {
        const key = cell.column.id as keyof IPassiveMonitoringCost;
        if (PassiveMonitoringCostKeys.find(item => item === key)) {
            return intlFormatter.format(cell.value);
        } else if (key === 'organizationName') {
            return <Link onClick={() => handleLinkToServiceTree(filteredData[cell.row.index]?.organizationId)}>{cell.value}</Link>;
        } else if (key === 'serviceGroupName') {
            return <Link onClick={() => handleLinkToServiceTree(filteredData[cell.row.index]?.serviceGroupId)}>{cell.value}</Link>;
        }

        return !cell.value ? '-' : cell.value;
    }, [filteredData, handleLinkToServiceTree]);

    const renderFooter = useCallback((key: string, index: number, value?: number) => {
        if (index === 0) return 'Total';
        if (value === undefined) return '-';

        if (PassiveMonitoringCostKeys.find(item => key === item)) return intlFormatter.format(value);

        return '-';
    }, []);

    useEffect(() => {
        if (!query.isLoading) {
            setData(query.data);
        }
        setLoading(query.isLoading);
    }, [query.isLoading, query.data]);

    useEffect(() => {
        setFilters([]);
    }, [props.type]);

    const columns : Column<IPassiveMonitoringCost>[] = useMemo(() => {
        return PassiveMonitoringCostTableKeys[props.type].map((key, index) => ({
            id: key,
            accessor: key,
            sortType: 'basic',
            Header: (
                PassiveMonitoringCostDisplayNames[key]
            ),
            Footer: () => renderFooter(key, index, filteredTotal && filteredTotal[key as keyof typeof filteredTotal]),
            Cell: renderCell,
            sortDescFirst: true,
        }));
    }, [filteredTotal, serviceTreeData.serviceTree, props.type]);

    const getRowId = useCallback((data: IPassiveMonitoringCostTableCost) => `${props.type}_${data.serviceId}_${data.serviceName}_${data.organizationId}_${data.organizationName}_${data.serviceGroupId}_${data.serviceGroupName}`, [props.type]);

    const tableInstance = useTable({
        columns,
        data: filteredData,
        getRowId,
        initialState: {
            sortBy: [{
                id: getDefaultSortColumn(props.type),
                desc: true,
            }],
        },
        disableSortRemove: true,
    } as TableOptions<IPassiveMonitoringCostTableCost> & UseSortByOptions<IPassiveMonitoringCostTableCost>, useSortBy) as (TableInstance<IPassiveMonitoringCostTableCost> & UseSortByInstanceProps<IPassiveMonitoringCostTableCost>);

    useEffect(() => {
        tableInstance.setSortBy([{
            id: getDefaultSortColumn(props.type),
            desc: true,
        }]);
    }, [props.type]);

    return (
        <div className={styles.costTableView}>
            <SectionHeader
                className={styles.sectionHeader}
                title="Top 50 Cost Service"
                extra={
                    <ActionButton
                        text="Download"
                        iconProps={{ iconName: 'Download' }}
                        onClick={handleDownload}
                    />}
            />
            <FilterList className={styles.filterList} data={filters} onChange={setFilters} options={filterOptions[props.type].map((key) => ({ key, displayName: PassiveMonitoringCostDisplayNames[key], type: FilterType.Number }))} />
            <StickyTable
                styles={{
                    container: styles.tableContainer,
                    bodyContainer: styles.tableBodyContainer,
                    footerContainer: styles.tableSectionContainer,
                    headerContainer: styles.tableSectionContainer,
                    stickyFooterContainer: styles.tableStickyFooterContainer,
                }}
                key={props.type}
                cellOption={getCellOptions}
                headerOption={getHeaderOption}
                footerOption={getFooterOption}
                loading={isLoading}
                loadMore={false}
                emptyText="Selected services don't have data"
                table={tableInstance}
                stickyPositon={{ header: { offsetTop: -40 }, footer: { offsetBottom: 0 } }}
            />
        </div>
    );
};

export default PassiveMonitoringCostTable;
