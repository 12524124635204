import styles from './Table.less';

import React, { useCallback, useEffect, useState } from "react";
import { CheckboxVisibility, CommandBar, DetailsHeader, DetailsList, DetailsListLayoutMode, GroupHeader, IColumn, ICommandBarItemProps, IDetailsHeaderProps, IGroup, IGroupHeaderProps, IIconProps, IRenderFunction, SelectionMode, Separator, TextField } from "@fluentui/react";
import { useMsal } from "@azure/msal-react";
import { Selection } from '@fluentui/react/lib/Selection';
import { useLocation } from "react-router-dom";
import { useBoolean } from "@fluentui/react-hooks";

import { Pages } from "../../../../models/Nav";
import { CopsResourceTypeEnum, EfficiencyStatusEnum, EfficiencyTrackerReductionPlan, PlatformTypeEnum, resourceTypeUnitDictionary } from "../../../../models/EfficiencyTracker";
import { useGotoPage } from "../../../../hooks/useGotoPage";
import { useIEfficiencyTrackerProject } from "../../../../hooks/useEfficiencyTrackerProject";
import MoreVerticals from "../Tools/MoreVerticals";
import { formatValue, getTargetDate } from "../Tools/ExportFunction";
import EfficiencyStatus from "../Tools/EfficiencyStatus";
import PlanImproveStatus from '../Tools/PlanImproveStatus';
import EmptyState from '../../../common/state/EmptyState';
import LoadingState from '../../../ResponseBoundary/LoadingState';


const searchIconProps: IIconProps = { iconName: 'Search', styles: { root: { color: 'blue' } } };

const columnKeys = {
    Name: 'Name',
    Id: 'Id',
    ResourceType: 'ResourceType',
    ImproveStatus: 'ImproveStatus',
    ConfidenceLevel: 'ConfidenceLevel',
    CreateTime: 'CreateTime',
    TargetDate: 'TargetDate',
    Status: 'Status',
    Owner: 'Owner',
    Platform: 'Platform',
    ResourceTypeUOM: 'ResourceTypeUOM',
    EfficiencyTargetCatagory: 'EfficiencyTargetCatagory',
};

const columns: IColumn[] = [
    {
        key: columnKeys.Name, name: 'Name[Id]', minWidth: 20, maxWidth: 90, isResizable: true, className: styles.PrimaryColumn
    },
    {
        key: columnKeys.Id, name: 'Plan ID', minWidth: 30, maxWidth: 65, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: columnKeys.ResourceType, name: 'Resource', minWidth: 20, maxWidth: 60, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: columnKeys.ImproveStatus, name: 'Status', minWidth: 60, maxWidth: 75, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: columnKeys.ConfidenceLevel, name: 'Confidence', minWidth: 20, maxWidth: 75, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: columnKeys.CreateTime, name: 'Submission date', minWidth: 50, maxWidth: 110, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: columnKeys.TargetDate, name: 'Target date', minWidth: 50, maxWidth: 110, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: columnKeys.Status, name: 'Submission status', minWidth: 60, maxWidth: 120, isResizable: false, className: styles.nomarlColumn
    },
    //{
    //    key: columnKeys.QualityForMap, name: 'Qualitiy for MAP',  minWidth: 30, maxWidth: 120, isResizable: true, className: styles.nomarlColumn
    //},
    {
        key: columnKeys.Owner, name: 'Owner', minWidth: 20, maxWidth: 150, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: columnKeys.Platform, name: 'Platform', minWidth: 20, maxWidth: 60, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: columnKeys.ResourceTypeUOM, name: 'Resource UOM', minWidth: 20, maxWidth: 100, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: columnKeys.EfficiencyTargetCatagory, name: 'Category', minWidth: 20, maxWidth: 75, isResizable: false, className: styles.nomarlColumn
    },
];

//Currently useless
//const overflowItems: ICommandBarItemProps[] = [
//    {
//        key: 'moveItem',
//        text: 'More ...',
//        iconProps: { iconName: 'MoveToFolder' },
//        onClick: () => console.log('Move item clicked'),
//    },
//    // Add more overflow items as needed
//];

interface IProjectDetailsTable {
    IsMyProject: boolean;
    Platform: string[];
}

const ProjectDetailsTable: React.FC<IProjectDetailsTable> = (props) => {
    //Get location & account
    const gotoPage = useGotoPage();
    const { instance } = useMsal();
    const location = useLocation();
    const account = instance.getActiveAccount();

    //Get project&plan
    const { project: project, plans: plans, isLoading, refetch, isRefetching } = useIEfficiencyTrackerProject();
    const [tableVisible, { setFalse: setTableVisibleDisable, setTrue: setTableVisibleEnable }] = useBoolean(false);
    const [group, setGroup] = useState<IGroup[] | undefined>([]);
    const [allPlans, setAllPlans] = useState<EfficiencyTrackerReductionPlan[]>([]);
    const [selectPlans, setSelectPlans] = useState<EfficiencyTrackerReductionPlan[]>([]);
    // Set plan status Storage
    const [planStatuses, setPlanStatuses] = useState<{ [planId: number]: EfficiencyStatusEnum }>({});

    // Refresh table
    const [refreshIndex, setRefreshIndex] = useState<number>(0);
    //Get Columns
    const [newColumns, setnewColumns] = useState<IColumn[]>(columns || []);

    //Select
    const [selectionDetails, setSelectionDetails] = React.useState<string>('');
    const [selectedIndices, setSelectedIndices] = useState<number[]>([]);

    const _selection = new Selection({
        onSelectionChanged: () => {
            setSelectionDetails(_getSelectionDetails());
            setSelectedIndices(_selection.getSelectedIndices());
        },
    });
    
    const _getSelectionDetails = (): string => {
        const selectionCount = _selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return 'No items selected';
            case 1:
                return '1 item selected';
            default:
                return `${selectionCount} items selected`;
        }
    };

    const handleStatusGet = useCallback((planId: number, status: EfficiencyStatusEnum) => {
        setPlanStatuses(prevStatuses => ({
            ...prevStatuses,
            [planId]: status
        }));
    }, []);

    function convertItemToCSVRow(item: EfficiencyTrackerReductionPlan): string {
        const csvValues = [
            item.ProjectId,
            item.Id,
            project.find(project => project.Id === item.ProjectId)?.Name,
            planStatuses[item.Id] as string,
            item.CreateTime.toISOString(),
            getTargetDate(item).toISOString(),
            item.Status || "",
            item.Platform,
            item.Owner,
            item.ConfidenceLevel,
            item.ResourceType,
            resourceTypeUnitDictionary[item.ResourceType as CopsResourceTypeEnum],
            item.EfficiencyTargetCatagory,
            `"${JSON.stringify(item.Components).replace(/"/g, '""')}"`, // Process string values to ensure that commas do not affect the CSV format
        ];

        return csvValues.join(',');
    }


    const _download = () => {
        const selectedItems = selectedIndices.map(index => selectPlans[index]);
        const customHeaders = [
            "ProjectId",
            "PlanId",
            "Name",
            "Status",
            "Submission date",
            "Target Date",
            "Submission status",
            "Platform",
            "Owner",
            "Confidence",
            "Resource",
            "ResourceUOM",
            "Catagory",
            "Components",
        ];
        const csvHeader = customHeaders.join(',');
        const csvRows = selectedItems.map(item => convertItemToCSVRow(item));
        const csvContent = "data:text/csv;charset=utf-8," + [csvHeader, ...csvRows].join('\n');

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `SelectedProject(${selectedItems.length}).csv`);
        document.body.appendChild(link);
        link.click();
    };


    const items: ICommandBarItemProps[] = [
        {
            key: 'AddProject',
            text: 'Add project',
            iconProps: { iconName: 'Add' },
            onClick: () => {
                const project = props.IsMyProject ? Pages.MyProjects : Pages.AllProjects;
                const platform = props.Platform[0] === PlatformTypeEnum.Baremetal.toString() ? Pages.BaremetalTracker : Pages.VirtualizedTracker;
                gotoPage(`${Pages.EfficiencyTrackerV2}/${project}/${platform}/${Pages.EFAddProjectPage}`)
            },
        },
        {
            key: 'DownloadProject',
            text: 'Export',
            iconProps: { iconName: 'Download' },
            onClick: () => _download(),
            disabled: selectedIndices.length == 0,
        },
        {
            key: 'RefreshProject',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: () => {
                refetch();
                setRefreshIndex(refreshIndex + 1);
            },
        },
        {
            key: 'FilterProject',
            onRender: () => (
                <TextField
                    className={styles.Filter}
                    iconProps={ searchIconProps }
                    placeholder= "Filter by name"
                    onChange={(ev, text) => _onFilter(ev, text)}
                />
            ),
        },
    ];

    const farItems: ICommandBarItemProps[] = [
        {
            key: 'SelectProject',
            text: selectionDetails,
            disabled: true,
        },
        {
            key: 'CountProject',
            text: `Total ${selectPlans.length} item${selectPlans.length > 1 ? 's' : ''}`,
            disabled: true,
        },
    ];

    useEffect(() => {
        if (isLoading || isRefetching) {
            setAllPlans([]);
            setTableVisibleDisable();
            return;
        }
        if (!plans || !project) return;
        const filteredPlans = plans.filter(plan => {
            const statusFilter = plan.Status !== "Deleted"; //exclude Delete plan.
            const platformFilter = props.Platform.includes(plan.Platform);
            const isMyProjectFilter = props.IsMyProject ? plan.Owner == account?.username: true;
            return statusFilter && platformFilter && isMyProjectFilter;
        });

        setGroup(groupPlans(filteredPlans));
        setAllPlans(filteredPlans);
        setSelectPlans(filteredPlans);
        setTableVisibleEnable();
        setnewColumns(columns);
    }, [account?.username, isLoading, isRefetching, props.IsMyProject, props.Platform]);

    useEffect(() => {
        setSelectPlans(allPlans);
    }, [allPlans]);

    const _onColumnClick = (event?: React.MouseEvent<HTMLElement>, column?: IColumn): void => {
        let sortedItems = allPlans;
        if (!column || !column.key || column.key !== 'Name') return;
        let isSortedDescending = column.isSortedDescending;

        // If sorted this column, flip it.
        if (column.isSorted) {
            isSortedDescending = !isSortedDescending;
        }

        // Sort the items.
        sortedItems = _copyAndSort(sortedItems, column.key, isSortedDescending);

        setGroup(groupPlans(sortedItems));
        setAllPlans(sortedItems);
        setnewColumns(newColumns.map((col: IColumn) => {
            const newCol = { ...col };
            newCol.isSorted = newCol.key === column.key;

            if (newCol.isSorted) {
                newCol.isSortedDescending = isSortedDescending;
            }

            return newCol;
        }));
    };

    const _onFilter = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string | undefined): void => {
        const filterPlans = text ? allPlans.filter(i => {
            const projectMatch = project.find(project => project.Id === i.ProjectId);
            return projectMatch && projectMatch.Name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        }) : allPlans;
        setGroup(groupPlans(filterPlans));
        setSelectPlans(filterPlans);
    };

    const onGroupHeaderClick = (group: IGroup | undefined) => {
        if (!group) return;
        gotoPage(`${location.pathname}/${Pages.EFSubPage}/${group.data as number}`)
    };

    const onRenderHeader: IRenderFunction<IGroupHeaderProps> = (props) => {
        if (!props) return null;

        const onHeaderClick = () => onGroupHeaderClick(props.group);

        return (
            <div>
                <GroupHeader
                    {...props}
                    onGroupHeaderClick={onHeaderClick}
                />
            </div>
        );
    };

    function _onItemInvoked(item: EfficiencyTrackerReductionPlan): void {
        gotoPage(`${location.pathname}/${Pages.EFSubPage}/${item.Id}`)
    }

    function _onRenderDetailsHeader(props: IDetailsHeaderProps | undefined) {
        return <DetailsHeader {...(props as IDetailsHeaderProps)} ariaLabelForToggleAllGroupsButton={'Expand collapse groups'} />;
    }

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        if (columnKey === columnKeys.Name) {
            return [...items].sort((a: T, b: T) => {
                const key = 'ProjectId' as keyof T;
                const projectNameA = project.find(proj => proj.Id === a[key])?.Name.toLowerCase() || '';
                const projectNameB = project.find(proj => proj.Id === b[key])?.Name.toLowerCase() || '';
                return ((isSortedDescending ? projectNameA < projectNameB : projectNameA > projectNameB) ? 1 : -1);
            });
        } else {
            const key = columnKey as keyof T;
            return [...items].sort((a: T, b: T) => {
                const projectIdKey = 'ProjectId' as keyof T;
                if (a[projectIdKey] !== b[projectIdKey]) {
                    return a[projectIdKey] < b[projectIdKey] ? -1 : 1;
                }
                return ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1);
            });
        }
    }

    function _renderItemColumn(item: EfficiencyTrackerReductionPlan, index?: number, column?: IColumn): React.ReactNode {
        let fieldContent = null;
        if (column?.key == columnKeys.ResourceTypeUOM) {
            fieldContent = resourceTypeUnitDictionary[item.ResourceType];
        }
        else if (column?.key == columnKeys.TargetDate) {
            fieldContent = getTargetDate(item);
        }
        else {
            fieldContent = item[column?.key as keyof EfficiencyTrackerReductionPlan];
        }

        switch (column?.key) {
            case columnKeys.Name:
                return <span><MoreVerticals ProjectId={item.ProjectId} PlanId={item.Id} AllowDelete={item.Owner === account?.username} /></span>;// <span onClick={() => _onItemInvoked(item)} style={{ cursor: 'pointer' }}>{formatValue(fieldContent)}</span>;
            case columnKeys.ImproveStatus:
                return <PlanImproveStatus planId={item.Id} onStatusGet={(status) => handleStatusGet(item.Id, status)} />;
            case columnKeys.Status:
                return <EfficiencyStatus Status={item.Status as EfficiencyStatusEnum} />;
            case columnKeys.ConfidenceLevel:
                return <EfficiencyStatus Status={item.ConfidenceLevel as EfficiencyStatusEnum} />;
            default:
                return <span>{formatValue(fieldContent)}</span>;
        }
    }

    function groupPlans(
        targetPlans: EfficiencyTrackerReductionPlan[],
    ): IGroup[] {
        // create a Map
        const groupMap = new Map<number, EfficiencyTrackerReductionPlan[]>();

        // Group filteredPlans according to the ProjectId
        targetPlans.map(plan => {
            if (!groupMap.has(plan.ProjectId)) {
                groupMap.set(plan.ProjectId, []);
            }
            groupMap.get(plan.ProjectId)!.push(plan);
        });

        let startIndex = 0;
        // Converts the packet information to IGroup[] format
        const groupedPlans: IGroup[] = Array.from(groupMap.entries()).map(([projectId, plans]) => {
            const projectName = project.find(item => item.Id === projectId)?.Name || "Unknown";

            const group: IGroup = {
                key: projectId.toString(),
                name: `${projectName} [${projectId}]`,
                startIndex: startIndex,
                count: plans.length,
                level: 0,
                isCollapsed: false,
                data: plans[0].Id,
            };
            startIndex += plans.length; // accumulate startIndex
            return group;
        });

        return groupedPlans;
    }

    return (
        <div>
            <Separator styles={{ root: styles.Separator }} />
            <CommandBar
                items={items}
                //overflowItems={overflowItems}
                farItems={farItems}
            />
            {
                tableVisible ? (
                    allPlans.length > 0 ?
                        <DetailsList
                            compact={false}
                            key={"DetailsList-" + plans.length + "refreshIndex:" + refreshIndex}
                            items={selectPlans}
                            columns={newColumns}
                            groups={group}
                            groupProps={{
                                onRenderHeader,
                            }}
                            onRenderItemColumn={_renderItemColumn}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="select row"
                            onColumnHeaderClick={_onColumnClick}
                            onItemInvoked={_onItemInvoked}
                            checkboxVisibility={CheckboxVisibility.always}
                            selection={_selection}
                            selectionMode={SelectionMode.multiple}
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            selectionZoneProps={{
                                disableAutoSelectOnInputElements: true,
                                isSelectedOnFocus: false
                            }}
                            onRenderDetailsHeader={_onRenderDetailsHeader}
                        /> :
                        <div className={styles.empty}>
                            <div>
                                <EmptyState />
                            </div>
                            <p>No current projects. Please add project to see data.</p>
                        </div>
                ) : <LoadingState />
            }
            
        </div>
    );
};

export default ProjectDetailsTable;