import { Stack, StackItem } from "@fluentui/react";
import { TelemetrySourceTypeEnum, TelemetrySourceTypeEnum2, TelemetrySourceTypeName, TelemetrySourceTypeName2 } from "../../../models/TelemetryCogsModel";

import React from "react";
import TelemetryTrendChart from "./TelemetryTrendChart";
import TelemetryTrendChartForCosmosBlueshift from "./TelemetryTrendChartCosmosBlueshift";
import commonStyles from "../../common/styles/Common.less";
import styles from "./TelemetryChart.less";
import { useCategoryFilters } from "../../../hooks/useFilters";
import { values } from "lodash";

interface ITelemetryChartProps {
    type: TelemetrySourceTypeEnum;
}

const TelemetryChart: React.FC<ITelemetryChartProps> = (props) => {
    const globalFiltersData = useCategoryFilters().filters;
    
    if (props.type === TelemetrySourceTypeEnum.Cosmos) {
        return (
            <div className={commonStyles.gridWrapper}>
                <div className={commonStyles.gridColumn2}>
                    <TelemetryTrendChartForCosmosBlueshift sources={[TelemetrySourceTypeEnum2.CosmosStorage]} title={`${TelemetrySourceTypeName2[TelemetrySourceTypeEnum2.CosmosStorage]} Cost Trends`} />
                    <TelemetryTrendChartForCosmosBlueshift sources={[TelemetrySourceTypeEnum2.CosmosProcessing]} title={`${TelemetrySourceTypeName2[TelemetrySourceTypeEnum2.CosmosProcessing]} Cost Trends`} />
                </div>
            </div>
        );
    }

    if (props.type === TelemetrySourceTypeEnum.Blueshift) {
        return (
            <div className={commonStyles.gridWrapper}>
                <div className={commonStyles.gridColumn2}>
                    <TelemetryTrendChartForCosmosBlueshift sources={[TelemetrySourceTypeEnum2.BlueshiftStorage]} title={`${TelemetrySourceTypeName2[TelemetrySourceTypeEnum2.BlueshiftStorage]} Cost Trends`} />
                    <TelemetryTrendChartForCosmosBlueshift sources={[TelemetrySourceTypeEnum2.BlueshiftProcessing]} title={`${TelemetrySourceTypeName2[TelemetrySourceTypeEnum2.BlueshiftProcessing]} Cost Trends`} />
                </div>
            </div>
        );
    }

    return (
        <div className={commonStyles.gridWrapper}>
            <div className={commonStyles.gridColumn2}>
                <TelemetryTrendChart sources={[props.type]} title={`${TelemetrySourceTypeName[props.type]} Cost Trends`} />
            </div>
        </div>
    );
};

export default TelemetryChart;
