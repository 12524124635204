/** Models of interfaces and enum used by efficiency tracker */

/** The complete form used by backend */
export interface IEfficiencyTrackerProject {
    EfficiencyTrackerProject: EfficiencyTrackerProject;
    // TODO: Simplify this or extend frontend to support multiple plans per project?
    EfficiencyTrackerReductionPlans: EfficiencyTrackerReductionPlan[];
}

/** Basic information for efficiency project */
export interface EfficiencyTrackerProject {
    Id: number;
    Name: string;
    Owner: string;
    CreateTime: Date;
    Description: string;
}

/** Reduction plan for a efficiency project */
export interface EfficiencyTrackerReductionPlan {
    Id: number;
    ProjectId: number;
    Target: string;
    EfficiencyTrackerReductionPlanRegionConfigs: string; // EfficiencyTrackerReductionPlanRegionConfig[];
    Components: string;// Component[];
    Status?: string;
    ConfidenceLevel: string;
    QualityForMap?: string;
    Platform: PlatformTypeEnum;
    Owner: string;
    CreateTime: Date;
    ResourceType: CopsResourceTypeEnum;
    EfficiencyTargetCatagory: string;
    TrackingGap: number;
}

/** Plan information for each region  
 *  Note: this will be converted to a string when sent to the backend
*/
export interface EfficiencyTrackerReductionPlanRegionConfig {
    BaseLineTime: Date;
    BaseLineValue: string;
    AzureRegion?: string;
    Region?: string;
    Targets: string;// TargetPair[];
    EffectivePeriod: Date;
}

/** Component type and value, eg: {Type: "ServiceId", Value: "my-service-id"}
 *  Note: this will be converted to a string when sent to the backend
*/
export interface Component {
    Type: ComponentTypeEnum;
    Value: string;
}

/** Monthly target */
export interface TargetPair {
    Month: Date;
    Value: number;
}
export interface ConsumptionDetail {
    [key: string]: TargetPair[];
}

/** Consumption detail for a azure region */
export interface AzrueRegionConsumption {
    region: string;
    totalCores: number;
}

/** Component type used to identify the actual resource usage of an efficiency project*/
export enum ComponentTypeEnum {
    Process = "Process",
    RestApp = "RestApp",
    Shard = "Shard",
    Region = "Region",
    Forest = "Forest",
    Dag = "Dag",
    Sku = "Sku",
    DagConfiguration = "DagConfiguration",
    AzureRegion = "AzureRegion",
    ServiceId = "ServiceId",
    SubscriptionId = "SubscriptionId",
}

/** Type of platform where the resource reduction will take place*/
export enum PlatformTypeEnum {
    Baremetal = "Exchange",
    Public = "Public",
    AzSC = "AZSC",
    COSMIC = "COSMIC",
}

export enum EfficiencyTargetCategoryEnum {
    Demands = "Demands",
    Capacity = "Capacity",
}

/** Type of resource to be reduced */
export enum CopsResourceTypeEnum {
    CPU = "CPU",
    Memory = "Memory",
    SSD = "SSD",
    HDD = "HDD",
    IO = "IO",
    VCore = "VCore",
}

/** Usage unit of resource */
export const resourceTypeUnitDictionary: Record<CopsResourceTypeEnum, string> = {
    [CopsResourceTypeEnum.CPU]: " Cores ",
    [CopsResourceTypeEnum.Memory]: " GB ",
    [CopsResourceTypeEnum.SSD]: " TB ",
    [CopsResourceTypeEnum.HDD]: " TB ",
    [CopsResourceTypeEnum.IO]: " RW Osp/Sec ",
    [CopsResourceTypeEnum.VCore]: " Cores ",
};

export enum EfficiencyStatusEnum {
    // Improve status
    OnTrack = "On track",
    Delayed = "Delayed",
    AtRisk = "At risk",

    // submission Status
    Draft = "Draft",
    Archive = "Archive",
    Approved = "Approved",
    Submitted = "Submitted",
    Rejected = "Rejected",
    Deleted = "Deleted",

    // confidence level
    High = "High",
    Medium = "Medium",
    Low = "Low",

    //Loading status
    Loading = "Loading",
    Error = "Error",
}

/** Type of Add Project/Plan Enum */
export enum AddProjectTypeEnum {
    AddProject = "AddProject",
    EditProject = "EditProject",
    AddPlan = "AddPlan", //AddPlan2ExistProject
}


// Download for region data
export interface DownloadRegionPair {
    Region: string;
    dataPairList: TargetActualGapPair[];
}
export interface TargetActualGapPair {
    Time: Date;
    target: number;
    actual?: number;
    gap?: number;
}

/** Reduction plan for each region */
export interface IRegionTarget {
    Region: string;
    BaseLineValue: string;
    TotalTarget: number;
    Targets: TargetPair[];
}

/** The complete form used by frontend to collect efficiency project data */
export interface IEfficiencyProjectForm {
    ProjectID: number,
    PlanID: number,
    Owner: string,
    ProjectName: string,
    ConfidenceLevel: string,
    Description: string,
    ResourceType: CopsResourceTypeEnum,
    Platform: PlatformTypeEnum | undefined,
    EfficiencyTargetCatagory: EfficiencyTargetCategoryEnum;
    ServiceID: string[],
    SubscriptionId: string[],
    Process: Component[];
    RestApp: Component[];
    Shard: Component[];
    Forest: Component[];
    Dag: Component[];
    DagConfiguration: Component[];
    Sku: Component[];
    BaseLineTime: Date,
    EffectivePeriod: number | undefined,
    RegionalTargets: IRegionTarget[],
    TrackingGap: number;
}

/**Schema used to store all error messages during project creation/editing */
export interface IErrorDict {
    [key: string]: string;
}

/*** V1 reserved ******* */
// Plan extend
export interface EfficiencyTrackerReductionPlanExtend {
    Id: number;
    ProjectId: number;
    Target: string;
    Status?: string;
    ConfidenceLevel: string;
    QualityForMap?: string;
    Platform: string;
    Owner: string;
    CreateTime: Date;
    ResourceType: CopsResourceTypeEnum;
    EfficiencyTargetCategory: string;
    BaseLineTime: Date;
    BaseLineValue: string;
    Region: string;
    Targets: string;// TargetPair[];
    EffectivePeriod: Date;
    TrackingGap: string;
    Process?: string;
    RestApp?: string;
    Shard?: string;
    Forest?: string;
    Dag?: string;
    DagConfiguration?: string;
    Sku?: string;
}

// display for plan extend details
export interface EfficiencyTrackerReductionPlanExtendDisplayDetails {
    Region: string;
    ResourceType: CopsResourceTypeEnum;
    BaseLineValue: string;
    ReductionPercentageTarget: string;
    ReductionTarget: string;
    LatestValue: string;
    EndGoal: string;
    Gap: string;
    Status: string;
}

// 
export interface PlanRegionConfigTable {
    Region: string;
    Current: number;
    Targets: TargetPair[];
    totalTarget: number;
}

export const EfficiencyTrackerReductionPlanExtendDisplayDetailsDictionary: Record<keyof EfficiencyTrackerReductionPlanExtendDisplayDetails, string> = {
    Region: 'Region',
    ResourceType: 'Resource Type',
    BaseLineValue: 'Baseline Value',
    ReductionPercentageTarget: 'Total Percentage Target',
    ReductionTarget: 'Total Target',
    LatestValue: 'Latest Actual Value',
    EndGoal: 'Current Month Goal',
    Gap: 'Gap',
    Status: 'Status',
}

export const EfficiencyTrackerReductionPlanExtendDictionary: Record<keyof EfficiencyTrackerReductionPlanExtend, string> = {
    Id: 'Id',
    ProjectId: 'Project Id',
    Target: 'Target',
    Status: 'Status',
    ConfidenceLevel: 'Confidence Level',
    QualityForMap: 'Quality For Map',
    Platform: 'Platform',
    Owner: 'Owner',
    CreateTime: 'Create Time',
    ResourceType: 'Resource Type',
    EfficiencyTargetCategory: 'Efficiency Target Category',
    BaseLineTime: 'Baseline Time',
    BaseLineValue: 'Baseline Value',
    Region: 'Region',
    Targets: 'Targets',
    EffectivePeriod: 'Effective Period',
    Process: 'Process',
    RestApp: 'Rest App',
    Shard: 'Shard',
    Forest: 'Forest',
    Dag: 'Dag',
    DagConfiguration: 'Dag Configuration',
    Sku: 'Sku',
    TrackingGap: 'Tracking Gap',
};

export const TrackerTableProjectKeys: (keyof Partial<EfficiencyTrackerProject>)[] = ['Id', 'Name', 'CreateTime', 'Owner',];

export const TrackerTablePlansKeys: (keyof Partial<EfficiencyTrackerReductionPlanExtend>)[] =
    ['ResourceType', 'Region',
        'BaseLineTime', 'BaseLineValue', 'EffectivePeriod', 'EfficiencyTargetCategory', 'TrackingGap',
        'Status', 'ConfidenceLevel', 'QualityForMap', 'Platform',];

export const TrackerEditShowKeys: (keyof Partial<EfficiencyTrackerReductionPlanExtend>)[] =
    ['ResourceType', 'BaseLineTime', 'EffectivePeriod', 'ConfidenceLevel',
        'Process', 'RestApp', 'Dag', 'DagConfiguration', 'Sku', 'Forest', 'Shard',
        'EfficiencyTargetCategory', 'TrackingGap'];