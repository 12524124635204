import { IBreadcrumbItem, Pivot, PivotItem, Separator, Spinner } from '@fluentui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";

import styles from "../Page.less";

import { useGotoPage } from '../../../../../hooks/useGotoPage';
import { useEFTrackerBigBetsProject } from '../../../../../hooks/useEfficiencyTrackerProject';
import { EFTrackerBigBetsProject } from '../../../../../models/EfficiencyTrackerBigBets';
import { Pages } from '../../../../../models/Nav';
import BigBetsProjectDetailsTable from '../../../Components/BigBets/Table/BigBetsProjectDetailsTable';
import BigBetsPageWrapper from '../../BigBetsPageWrapper';
import BigBetsDetails from '../../../Components/BigBets/BigBetsDetails';
import BigBetsDetailsDiscussion from '../../../Components/BigBets/BigBetsDetailsDiscussion';

const BigBetsSubProjectView: React.FC = () => {
    //breadcrumb
    const gotoPage = useGotoPage();
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const projectlink = pathSegments[2];
    const Platform = pathSegments[3];
    const { instance } = useMsal();
    const account = instance.getActiveAccount();

    //Get project details
    const { projectId } = useParams();
    const { projects: allProjects, isLoading, isRefetching } = useEFTrackerBigBetsProject();
    const projectIdNumber: number = parseInt(projectId || "0", 10);

    //Initial
    const [invalidProject, setInvalidProject] = useState<boolean>(false);
    const [currentProject, setCurrentProject] = useState<EFTrackerBigBetsProject>();

    useEffect(() => {
        document.title = 'Efficiency Tracker';
    }, []);

    useEffect(() => {
        if (isLoading || isRefetching) return;
        const currentProject = allProjects.find(item => item.ProjectID === projectIdNumber);
        if (!currentProject) {
            setInvalidProject(true);
            return;
        }

        setCurrentProject(currentProject);
    }, [projectIdNumber, isLoading, isRefetching]);


    const breadcrumbItems: IBreadcrumbItem[] = [
        { key: 'Project', text: projectlink, onClick: () => gotoPage(`${Pages.EfficiencyTrackerV2}/${projectlink}/${Platform}`) },
        { key: 'Platform', text: Platform, onClick: () => gotoPage(`${Pages.EfficiencyTrackerV2}/${projectlink}/${Platform}`) },
        { key: 'ProjectName', text: currentProject?.Name || "Project Details" },
    ];

    return (
        <BigBetsPageWrapper
            key={"PageWrapper_" + projectIdNumber}
            title={currentProject?.Name || "Project Details"}
            breadcrumbItems={breadcrumbItems}
            description={currentProject?.Notes}
            EditLink={`${Pages.EfficiencyTrackerV2}/${projectlink}/${Platform}/${Pages.EFEditPage}/${projectIdNumber}`}
            AllowDelete={currentProject?.Owner === account?.username}
            ShareProjectId={currentProject?.ProjectID}
            DeleteProjectId={currentProject?.ProjectID}
            Owner={currentProject?.Owner}
        >
            <div key={projectIdNumber}>
                {invalidProject ? (
                    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '6px', paddingTop: '12px' }}>
                        Error: Sub Project not found
                    </div>
                ) : (!currentProject) ? (
                        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '6px', paddingTop: '12px' }}>
                            <Spinner label="Loading..." labelPosition="right" />
                        </div>
                    ) : (
                            <>
                                <BigBetsProjectDetailsTable data={currentProject} />
                                <BigBetsDetails data={currentProject} />
                                <BigBetsDetailsDiscussion ProjectID={currentProject.ProjectID} />
                            </>
                )}
            </div>
        </BigBetsPageWrapper>
    );

};

export default BigBetsSubProjectView;
