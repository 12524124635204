import { Cell, Column, HeaderGroup, Row, TableInstance, TableOptions, UseSortByInstanceProps, UseSortByOptions, useSortBy, useTable } from 'react-table';
import { FilterList, FilterType, IFilterItem } from '../filter/filterList/FilterList';
import { FiltersAction, FiltersView } from '../../reducers/filterReducer';
import { IIconProps, Icon, Link } from '@fluentui/react';
import { IServiceTreeData, getCategoryByServiceTreeLevel } from '../../reducers/serviceTreeReducer';
import { ITelemetryResourceCost, ITelemetryServiceCost, ITelemetryTotalCost, TelemetryCostDisplayNames, TelemetryCostKeys, TelemetryCostTableKeys, TelemetrySourceTypeEnum } from '../../models/TelemetryCogsModel';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ServiceTree, ServiceTreeItem, ServiceTreeLevel } from '../../models/serviceTree';
import StickyTable, { IStickyTableCellOption, IStickyTableFooterOption, IStickyTableHeaderOption } from '../common/table/StickyTable';
import { getResourceTelemetryCogs, getServiceTelemetryCogs, getTelemetryServiceCostSummary, getTotalTelemetryCogs } from '../../services/TelemetryCogsService'
import { useDispatch, useSelector } from 'react-redux';

import { ActionButton } from '@fluentui/react';
import { IAppState } from '../../store';
import { SectionHeader } from '../common/SectionHeader';
import { exportToCsv } from '../../utils/common';
import { formatNumber } from '../../utils/currency';
import { getServiceTreePath } from '../../utils/common';
import { match } from '../filter/filterList/FilterUtils';
import styles from './TelemetryCostTable.less';
import { useCategoryFilters } from '../../hooks/useFilters';
import { useGetTelemetryServiceCost } from '../../hooks/useTelemetryCost';

export interface ITelemetryCostTableProps {
    type: TelemetrySourceTypeEnum;
}

const feedbackIconProps: IIconProps = { iconName: "Feedback" };

const getCellOptions = (cell: Cell) : IStickyTableCellOption => {
    return {
        props: {
            key: cell.column.id,
            className: TelemetryCostKeys.find((item) => item === cell.column.id) ? styles.textAlignRight : '',
        }
    }
};

const getHeaderOption = (header: HeaderGroup): IStickyTableHeaderOption => {
    return {
        //tooltip: getHeaderTooltip(header.id as keyof ITelemetryServiceCost),
        props: {
            key: header.id,
            className: TelemetryCostKeys.find((item) => item === header.id) ? styles.textAlignRight : '',
        }
    }
};

const getFooterOption = (footer: HeaderGroup): IStickyTableFooterOption => {
    return {
        props: {
            key: footer.id,
            className: TelemetryCostKeys.find((item) => item === footer.id) ? styles.textAlignRight : '',
        }
    }
};

/*const getHeaderTooltip = (key: keyof ITelemetryServiceCost) => {
    switch (key) {
        case 'totalCost': return "Total Telemetry cost is the sum of Cosmos .";
        case 'cosmosStorageCost': return "";
        case 'cosmosProcessingCost': return "";
        case 'blueShiftStorageCost': return "";
        case 'blueShiftProcessingCost': return "";
        case 'kustoCost': return "";
        case 'genevaMdsCost': return "";
        case 'genevaMdmCost': return "";
        case 'ariaCost': return "";
    }
    return undefined;
}*/

const getDefaultSortColumn = (type: TelemetrySourceTypeEnum) : keyof ITelemetryServiceCost => {
    if (type === TelemetrySourceTypeEnum.Cosmos) return 'cosmosStorageCost';
    if (type === TelemetrySourceTypeEnum.Blueshift) return 'blueShiftStorageCost';
    if (type === TelemetrySourceTypeEnum.Kusto) return 'kustoCost';
    if (type === TelemetrySourceTypeEnum.GenevaMDS) return 'genevaMdsCost';
    if (type === TelemetrySourceTypeEnum.GenevaMDM) return 'genevaMdmCost';
    if (type === TelemetrySourceTypeEnum.Aria) return 'ariaCost';

    return 'totalCost';
};

const intlFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
});

const filterOptions: Record<TelemetrySourceTypeEnum, (keyof ITelemetryServiceCost)[]> = {
    [TelemetrySourceTypeEnum.All]: ['totalCost', 'cosmosStorageCost', 'cosmosProcessingCost', 'blueShiftStorageCost', 'blueShiftProcessingCost', 'kustoCost', 'genevaMdsCost', 'genevaMdmCost', 'ariaCost'],
    [TelemetrySourceTypeEnum.Cosmos]: ['cosmosStorageCost', 'cosmosProcessingCost'],
    [TelemetrySourceTypeEnum.Blueshift]: ['blueShiftStorageCost', 'blueShiftProcessingCost'],
    [TelemetrySourceTypeEnum.Kusto]: ['kustoCost'],
    [TelemetrySourceTypeEnum.GenevaMDS]: ['genevaMdsCost'],
    [TelemetrySourceTypeEnum.GenevaMDM]: ['genevaMdmCost'],
    [TelemetrySourceTypeEnum.Aria]: ['ariaCost'],
}

export const TelemetryTableDefaultPageSize = 50;

const TelemetryCostTable: React.FC<ITelemetryCostTableProps> = (props) => {
    const [filters, setFilters] = useState<IFilterItem[]>([]);
    const query = useGetTelemetryServiceCost(15000);
    const [data, setData] = useState<ITelemetryServiceCost[] | undefined>(query.data);
    const [isLoading, setLoading] = useState(query.isLoading);
    const [filteredTotal, setFilteredTotal] = useState<Record<TypedKeyOf<ITelemetryServiceCost, number>, number>>();
    const [filteredData, setFilteredData] = useState<ITelemetryServiceCost[]>(query.data || []);
    const serviceTreeData = useSelector<IAppState, IServiceTreeData>(state => state.serviceTree);
    const updateFilters = useCategoryFilters().updateFilters;

    useEffect(() => {
        if (!query.data) return;

        const newData = query.data?.filter(item => item.totalCost > 0).filter(item => !filters.find(filter => !match(item[filter.key as TypedKeyOf<ITelemetryServiceCost, number>], filter.operator, parseFloat(filter.value || "0")))).sort((a, b) => b.totalCost - a.totalCost). slice(0,50) || [];
        const newTotal = getTelemetryServiceCostSummary(newData);

        setFilteredData(newData);
        setFilteredTotal(newTotal);
    }, [query.data, filters]);

    const handleDownload = () => {
        exportToCsv(query.data || [], 'Telemetry Cost', TelemetryCostTableKeys[props.type], TelemetryCostDisplayNames, (key: string, value: string | number) => {
            if (value === undefined || value === null) return '-';

            return String(value);
        });
    };

    const handleLinkToServiceTree = useCallback((orgId?: string) => {
        if (!orgId) return;
        const serviceTreeNode = serviceTreeData.indexMap.get(orgId);
        if (!serviceTreeNode) return;

        if (serviceTreeNode.l === ServiceTreeLevel.Organization) {
            updateFilters(FiltersAction.Replace, {filters: {[getCategoryByServiceTreeLevel(serviceTreeNode.l)]: [serviceTreeNode.id]}, view: FiltersView.Breadcrumb});
        } else if (serviceTreeNode.l === ServiceTreeLevel.ServiceGroup) {
            updateFilters(FiltersAction.Replace, {filters: {[getCategoryByServiceTreeLevel(serviceTreeNode.l)]: [serviceTreeNode.id]}, view: FiltersView.Breadcrumb});
        }
    }, [serviceTreeData.indexMap, updateFilters]);

    const renderCell = useCallback((cell: Cell) =>  {
        const key = cell.column.id as keyof ITelemetryServiceCost;
        if (TelemetryCostKeys.find(item => item === key)) {
            return intlFormatter.format(cell.value);
        } else if (key === 'organizationName') {
            return <Link onClick={() => handleLinkToServiceTree(filteredData[cell.row.index]?.organizationId)}>{cell.value}</Link>;
        } else if (key === 'serviceGroupName') {
            return <Link onClick={() => handleLinkToServiceTree(filteredData[cell.row.index]?.serviceGroupId)}>{cell.value}</Link>;
        }

        return !cell.value ? '-' : cell.value;
    }, [filteredData, handleLinkToServiceTree]);

    const renderFooter = useCallback((key: string, index: number, value?: number) => {
        if (index === 0) return 'Total';
        if (value === undefined) return '-';

        if (TelemetryCostKeys.find(item => key === item)) return intlFormatter.format(value);

        return '-';
    }, []);

    useEffect(() => {
        if (!query.isLoading) {
            setData(query.data);
        }
        setLoading(query.isLoading);
    }, [query.isLoading, query.data]);

    useEffect(() => {
        setFilters([]);
    }, [props.type]);

    const columns : Column<ITelemetryServiceCost>[] = useMemo(() => {
        return TelemetryCostTableKeys[props.type].map((key, index) => ({
            id: key,
            accessor: key,
            sortType: 'basic',
            Header: (
                TelemetryCostDisplayNames[key]
            ),
            Footer: () => renderFooter(key, index, filteredTotal && filteredTotal[key as keyof typeof filteredTotal]),
            Cell: renderCell,
            sortDescFirst: true,
        }));
    }, [filteredTotal, serviceTreeData.serviceTree, props.type]);

    const getRowId = useCallback((data: ITelemetryServiceCost) => `${props.type}_${data.serviceId}_${data.serviceName}_${data.organizationId}_${data.organizationName}_${data.serviceGroupId}_${data.serviceGroupName}`, [props.type]);

    const tableInstance = useTable({
        columns,
        data: filteredData,
        getRowId,
        initialState: {
            sortBy: [{
                id: getDefaultSortColumn(props.type),
                desc: true,
            }],
        },
        disableSortRemove: true,
    } as TableOptions<ITelemetryServiceCost> & UseSortByOptions<ITelemetryServiceCost>, useSortBy) as (TableInstance<ITelemetryServiceCost> & UseSortByInstanceProps<ITelemetryServiceCost>);

    useEffect(() => {
        tableInstance.setSortBy([{
            id: getDefaultSortColumn(props.type),
            desc: true,
        }]);
    }, [props.type]);

    return (
        <div className={styles.costTableView}>
            <SectionHeader
                className={styles.sectionHeader}
                title="Top 50 Cost Service"
                extra={
                    <ActionButton
                        text="Download"
                        iconProps={{ iconName: 'Download' }}
                        onClick={handleDownload}
                    />}
            />
            <FilterList className={styles.filterList} data={filters} onChange={setFilters} options={filterOptions[props.type].map((key) => ({ key, displayName: TelemetryCostDisplayNames[key], type: FilterType.Number }))} />
            <StickyTable
                styles={{
                    container: styles.tableContainer,
                    bodyContainer: styles.tableBodyContainer,
                    footerContainer: styles.tableSectionContainer,
                    headerContainer: styles.tableSectionContainer,
                    stickyFooterContainer: styles.tableStickyFooterContainer,
                }}
                key={props.type}
                cellOption={getCellOptions}
                headerOption={getHeaderOption}
                footerOption={getFooterOption}
                loading={isLoading}
                loadMore={false}
                emptyText="Selected services don't have data"
                table={tableInstance}
                stickyPositon={{ header: { offsetTop: -40 }, footer: { offsetBottom: 0 } }}
            />
        </div>
    );
};

export default TelemetryCostTable;
