import styles from "./Page.less";

import * as React from "react";
import Highcharts from "highcharts";
import { Breadcrumb, CommandBar, IBreadcrumbItem, ICommandBarItemProps, IconButton, MessageBar, MessageBarType, Modal, PrimaryButton, Spinner, TextField } from "@fluentui/react";

import { useGotoPage } from "../../../hooks/useGotoPage";
import { deleteItem, postMethod } from "../../../utils/apiServiceBase";
import { useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import { Pages } from "../../../models/Nav";
import { useLocation } from "react-router-dom";
import { useEFTrackerBigBetsShareList } from "../../../hooks/useEfficiencyTrackerProject";

Highcharts.seriesTypes.line.prototype.drawLegendSymbol = Highcharts.seriesTypes.column.prototype.drawLegendSymbol;

const SucceedMessage = 'Submit successfully!';
const DeleteSucceedMessage = 'Submit successfully! Will go to landing page in 2 seconds.';
const FailedMessage = 'Submit failed!';

interface IProps {
    children: React.ReactNode;
    title: string;
    breadcrumbItems: IBreadcrumbItem[];
    description?: React.ReactNode;
    EditLink?: string;
    AllowDelete?: boolean;
    ShareProjectId?: number;
    DeleteProjectId?: number;
    Owner?: string;
}

const BigBetsPageWrapper = (props: IProps) => {
    // Get location
    const gotoPage = useGotoPage();
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const projectlink = pathSegments[2];
    const isBaremetal = pathSegments[3];

    // add share/delete for project
    const [showModal, setShowModal] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [email, setEmail] = useState('');
    const [showModalRequest, setShowModalRequest] = useState(false);
    const [requestState, setRequestState] = useState<string>('');
    const [isSubmitting, { setTrue: submitting, setFalse: submitDone }] = useBoolean(false);

    //Get share list
    const { list: list, refetch } = useEFTrackerBigBetsShareList(props.ShareProjectId || 0);

    //Far Items
    const farItems: ICommandBarItemProps[] = [
        props.EditLink
            ? {
                key: 'EditProject',
                text: 'Edit',
                iconProps: { iconName: 'Edit' },
                onClick: () => {
                    gotoPage(props.EditLink as string)
                },
            }
            : null,
        props.ShareProjectId
            ? {
                key: 'ShareProject',
                text: 'Share',
                iconProps: { iconName: 'Share' },
                onClick: () => {
                    setIsDelete(false);
                    setShowModal(true);
                    setRequestState('');
                },
            }
            : null,
        props.DeleteProjectId && props.AllowDelete
            ? {
                key: 'DeleteProject',
                text: 'Delete',
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    setIsDelete(true);
                    setShowModal(true);
                    setRequestState('');
                },
            }
            : null,
    ].filter(Boolean) as ICommandBarItemProps[];

    //Modal
    const handleModalClose = () => {
        setShowModal(false);
        setRequestState('');
        setShowModalRequest(false);
    };

    const handleEmailChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText: string | undefined) => {
        setEmail(newText || "");
        setShowModalRequest(false);
        setRequestState('');
    };

    //Share project by email
    const handleEmailSubmit = async () => {
        submitting();
        try {
            const response = await postMethod('api/efficiencytracker/bigbets/share?projectId=' + props.ShareProjectId + "&targetUser=" + email, null);
            const responseData = await response.text();
            if (response.ok) {
                setRequestState(SucceedMessage);
                refetch();
            } else {
                setRequestState(responseData);
            }
        } catch (error) {
            setRequestState('Error when Submitting:' + error);
        }
        submitDone();
        setEmail('');
        setShowModalRequest(true);
    };

    // Delete project
    const handleDeleteProject = () => {
        submitting();
        deleteItem('api/efficiencytracker/bigbets/delete?projectId=' + props.DeleteProjectId)
            .then((response) => {
                if (response.ok) {
                    setRequestState(DeleteSucceedMessage);
                    setTimeout(() => {
                        gotoPage(`${Pages.EfficiencyTrackerV2}/${projectlink}/${isBaremetal}`);
                    }, 2000);
                } else {
                    setRequestState(FailedMessage);
                }
            })
            .catch(() => {
                setRequestState('Error when Submitting');
            }).finally(() => {
                submitDone();
                setShowModalRequest(true);
            });
    };

    return (
        <div className={styles.page}>
            <Breadcrumb items={props.breadcrumbItems} className={styles.breadcrumb} />
            <div className={styles.header}>
                <div className={styles.title}>{props.title}</div>
                <div className={styles.buttons}>
                    <CommandBar
                        items={[]}
                        farItems={farItems}
                    />
                </div>
            </div>
            <div className={styles.content}>
               {props.description}
            </div>
            <Modal isOpen={showModal} onDismiss={handleModalClose} containerClassName={isDelete ? styles.modalContainerDelete : styles.modalContainer}>
                {isDelete ? (
                    <>
                        <div className={styles.modalHeader}>
                            <span >{"Delete this project"}</span>
                            <IconButton
                                className={styles.iconButton}
                                iconProps={{ iconName: "Cancel" }}
                                ariaLabel="Close"
                                onClick={handleModalClose}
                            />
                        </div>
                        {
                            showModalRequest && !isSubmitting && (
                                <MessageBar
                                    messageBarType={requestState === DeleteSucceedMessage ? MessageBarType.success : MessageBarType.error}>
                                    {requestState}
                                </MessageBar>
                            )
                        }
                        <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', bottom: 0 }}>
                            <PrimaryButton onClick={handleDeleteProject} style={{ margin: '8px' }}>Confirm</PrimaryButton>
                            <PrimaryButton onClick={handleModalClose} style={{ margin: '8px' }}>Cancel</PrimaryButton>
                            {isSubmitting && <Spinner label="Deleting project..." labelPosition="right" />}
                        </div>
                    </>
                ): (<>
                        <div className={styles.modalHeader}>
                            <span >{"Share edit access to others"}</span>
                            <IconButton
                                className={styles.iconButton}
                                iconProps={{ iconName: "Cancel" }}
                                ariaLabel="Close"
                                onClick={handleModalClose}
                            />
                        </div>
                        <div className={styles.modalBody}>
                            <TextField
                                label="MS Account Email"
                                value={email}
                                placeholder="Format:alias@microsoft.com"
                                onChange={handleEmailChange}
                            />
                            <div>
                                <div style={{ paddingTop: '10px', fontWeight: 'bold' }}>Who can edit:</div>
                                {list.length > 0 && (
                                    <div style={{ height: '165px', overflowY: 'auto' }}>
                                        <div>
                                            <strong>(Owner)</strong>{props.Owner}
                                        </div>
                                        {list.sort().map((item, index) => (
                                            <div key={"list" + item + index}>{item}</div>
                                        ))}
                                    </div>
                                )}
                                {list.length === 0 && (
                                    <div style={{ height: '165px', overflowY: 'auto' }}>
                                        <strong>(Owner)</strong>{props.Owner}
                                    </div>
                                )}
                            </div>
                        </div>
                        {
                            showModalRequest && !isSubmitting && (
                                <MessageBar
                                    messageBarType={requestState === SucceedMessage ? MessageBarType.success : MessageBarType.error}>
                                    {requestState}
                                </MessageBar>
                            )
                        }
                        <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', bottom: 0 }}>
                            <PrimaryButton onClick={handleEmailSubmit} style={{ margin: '8px' }}>Confirm</PrimaryButton>
                            <PrimaryButton onClick={handleModalClose} style={{ margin: '8px' }}>Cancel</PrimaryButton>
                            {isSubmitting && <Spinner label="Submitting share request..." labelPosition="right" />}
                        </div>
                    </>)
                }
               
            </Modal>
            {props.children}
        </div>
    );
};

export default BigBetsPageWrapper;