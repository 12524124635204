import { ITelemetryResourceCost, TelemetryLogComponent, TelemetryLogComponent2, TelemetrySourceTypeEnum, TelemetrySourceTypeEnum2, TelemetrySourceTypeName, TelemetrySourceTypeName2 } from "../../../models/TelemetryCogsModel";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";

import ChartContainer from "../../chart/ChartContainer";
import CommonConstants from "../../../models/constants/CommonConstants";
import { CostCardProps } from "../../common/costCard/CostCard";
import { LineChartSeries } from "../../common/LineChart";
import React from "react";
import { currencyFormatter } from "../../../utils/currency";
import { useGetTelemetryResourceCost } from "../../../hooks/useTelemetryCost";
import { useTrackHovering } from "../../../hooks/useTrack";

interface ITelemetryTrendChartProps2 {
    title: string;
    sources: TelemetrySourceTypeEnum2[];
}

const formatData = (value: number) => {
    return currencyFormatter(value, 2, "$");
};

const formatAxisData = (value: number) => {
    return currencyFormatter(value, 2, "$");
};

function getTelemetryCost2(source: TelemetrySourceTypeEnum2, telemetryCost: ITelemetryResourceCost) {
    switch (source) {
        case TelemetrySourceTypeEnum2.CosmosStorage:
            return telemetryCost.cosmosStorageCost;
        case TelemetrySourceTypeEnum2.CosmosProcessing:
            return telemetryCost.cosmosProcessingCost;
        case TelemetrySourceTypeEnum2.BlueshiftStorage:
            return telemetryCost.blueShiftStorageCost;
        case TelemetrySourceTypeEnum2.BlueshiftProcessing:
            return telemetryCost.blueShiftProcessingCost;
        case TelemetrySourceTypeEnum2.Kusto:
            return telemetryCost.kustoCost;
        case TelemetrySourceTypeEnum2.GenevaMDS:
            return telemetryCost.genevaMdsCost;
        case TelemetrySourceTypeEnum2.GenevaMDM:
            return telemetryCost.genevaMdmCost;
        case TelemetrySourceTypeEnum2.Aria:
            return telemetryCost.ariaCost;
        case TelemetrySourceTypeEnum2.All:
            return telemetryCost.cosmosProcessingCost + telemetryCost.cosmosStorageCost + telemetryCost.blueShiftStorageCost +
            telemetryCost.blueShiftProcessingCost + telemetryCost.kustoCost + telemetryCost.genevaMdsCost + telemetryCost.genevaMdmCost + telemetryCost.ariaCost;
        default:
            return 0;
    }
}

const TelemetryTrendChartForCosmosBlueshift: React.FC<ITelemetryTrendChartProps2> = ({ sources, title }) => {
    const query = useGetTelemetryResourceCost();

    const cards: CostCardProps[] = sources.map((source, index) => ({
        title: `Total ${TelemetrySourceTypeName2[source]} Cost`,
        cost: (query.data && query.data.reduce((sum, val) => sum + getTelemetryCost2(source, val) || 0, 0)) || 0,
        color: CommonConstants.DefaultColors[index],
        formatter: (value: number) => formatData(value),
    }));

    const series: LineChartSeries[] = sources.map((source, index) => ({
        color: CommonConstants.DefaultColors[index],
        name: TelemetrySourceTypeName2[source],
        data: query.data && query.data.map((singleMetric) => [singleMetric.timeStamp.valueOf(), getTelemetryCost2(source, singleMetric)]),
    }));

    const trackChartHovering = useTrackHovering(LogComponent.TelemetryView, LogElement.TelemetryTrendsChart, title, LogTarget.Chart, {
        dataSources: sources.map((item) => TelemetrySourceTypeName2[item]).join(","),
    });

    return (
        <ChartContainer
            headerProps={{ title }}
            loading={query.isLoading}
            cards={cards}
            chartType="line"
            chartProps={{
                series,
                height: 220,
                tooltipYFormatter: (value: number) => formatData(value),
                yFormatter: (value: number) => formatAxisData(value),
                containerProps: trackChartHovering,
            }}
            logProps={{
                logComponent: TelemetryLogComponent2[sources.length > 1 ? TelemetrySourceTypeEnum2.All : sources[0]],
                logElement: LogElement.TelemetryTrendsChart,
            }}
        />
    );
};

export default TelemetryTrendChartForCosmosBlueshift;