import PageHeader, { IPageHeaderProps } from '../common/PageHeader';

import PassiveMonitoringChart from './chart/PassiveMonitoringChart';
import PassiveMonitoringCostTable from './PassiveMonitoringCostTable';
import { PassiveMonitoringPlatformEnum } from '../../models/TelemetryCogsModel';
import React from 'react';
import { WarningBanner } from '../banner/WarningBanner';
import styles from './TelemetryView.less';
import { useGetBannersByTabAndDateQuery } from '../../hooks/useBannerQuery';

const PassiveMonitoringViewHeadersProps: Record<PassiveMonitoringPlatformEnum, IPageHeaderProps> = {
    [PassiveMonitoringPlatformEnum.All]: {
        title: "PassiveMonitoring",
        description: "The cost of Passive Monitoring is not actually billed but is displayed to illustrate the utilization of Passive Monitoring.",
        link: "https://dev.azure.com/O365Exchange/O365%20Core/_wiki/wikis/O365%20Core.wiki/63980/Cosman",
        linkText: "Learn more about Telemetry Cost",
    },
    [PassiveMonitoringPlatformEnum.Kusto]: {
        title: "PassiveMonitoring",
        description: "Major telemetry data fabrics (Cosmos, Blueshift, Kusto, Geneva, Aria), and all corresponding data assets.",
        link: "https://dev.azure.com/O365Exchange/O365%20Core/_wiki/wikis/O365%20Core.wiki/63980/Cosman",
        linkText: "Learn more about Telemetry Cost",
    },
    [PassiveMonitoringPlatformEnum.GenevaMDS]: {
        title: "PassiveMonitoring",
        description: "Major telemetry data fabrics (Cosmos, Blueshift, Kusto, Geneva, Aria), and all corresponding data assets.",
        link: "https://dev.azure.com/O365Exchange/O365%20Core/_wiki/wikis/O365%20Core.wiki/63980/Cosman",
        linkText: "Learn more about Telemetry Cost",
    },
};

interface IPassiveMonitoringViewProps {
    type: PassiveMonitoringPlatformEnum
}

const PassiveMonitoringView: React.FC<IPassiveMonitoringViewProps> = (props) => {
    const { data: allBanners } = useGetBannersByTabAndDateQuery(PassiveMonitoringPlatformEnum[props.type].toString());

    return (
        <div className={styles.telemetryView}>
            <PageHeader {...PassiveMonitoringViewHeadersProps[props.type]} className={styles.header} />
            <div className={styles.Bannerheader} >
                {
                    Boolean(allBanners?.length) &&
                    allBanners?.map(item =>
                    (
                        <WarningBanner key={item.id} bannerItem={item} />
                    ))
                }
            </div>
            <PassiveMonitoringChart type={props.type}/>
            <PassiveMonitoringCostTable type={props.type} />
        </div>
    );
};

export default PassiveMonitoringView;