/**Render the main page to create Efficiency Project, major sub-components:
 * 1. Show Steps
 * 2. Show Forms based on steps
 * 3. User controlled buttons like next, back, done
*/
import React, { useEffect, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import styles from "./AddProjectView.less";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Breadcrumb, IBreadcrumbItem, Stack } from '@fluentui/react';
import { useMsal } from "@azure/msal-react";
import { useImmer } from 'use-immer';
import { useLocation } from 'react-router-dom';

import { AddProjectTypeEnum, EfficiencyStatusEnum, IErrorDict } from '../../../../../models/EfficiencyTracker';
import { useGotoPage } from '../../../../../hooks/useGotoPage';
import { Pages } from '../../../../../models/Nav';
import { useEFTrackerBigBetsDiscussion, useEFTrackerBigBetsLatestByProjectId, useEFTrackerBigBetsProject, useEFTrackerBigBetsProjectHistory, useEFTrackerBigBetsReductionResult } from '../../../../../hooks/useEfficiencyTrackerProject';
import { AddEFTrackerBigBetsProjectRequest, BigBetsMetrics, EFTrackerBigBetsDiscussion, EFTrackerBigBetsProject, EFTrackerBigBetsProjectForm, EFTrackerBigBetsReductionResultForm } from '../../../../../models/EfficiencyTrackerBigBets';
import { postMethod } from '../../../../../utils/apiServiceBase';
import NewBigBetsProjectInfo from './Form/BigBetsProjectInfo';
import BigBetsReductionPlan from './Form/BigBetsReductionPlan';
import BigBetsConfirmation from './Form/BigBetsConfirmation';
import { useQueryClient } from 'react-query';
import { CompensateLocalDateToUTC } from '../../../Components/Tools/ExportFunction';

interface IAddProjectView {
    mode: AddProjectTypeEnum;
}

const AddProjectView: React.FC<IAddProjectView> = (props) => {
    //breadcrumb
    const gotoPage = useGotoPage();
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const projectlink = pathSegments[2];
    const platform = pathSegments[3];
    const uriId = parseInt(pathSegments[5], 10); //If edit mode, ProjectId

    const getBreadcrumbItems = () => {
        const breadcrumbItems: IBreadcrumbItem[] = [
            { key: 'Project', text: projectlink, onClick: () => gotoPage(`${Pages.EfficiencyTrackerV2}/${projectlink}/${platform}`) },
            { key: 'isBaremetal', text: platform, onClick: () => gotoPage(`${Pages.EfficiencyTrackerV2}/${projectlink}/${platform}`) },
        ];
        switch (props.mode) {
            case AddProjectTypeEnum.AddProject:
                breadcrumbItems.push({ key: 'AddProject', text: "Add Project" });
                break;
            case AddProjectTypeEnum.EditProject:
                breadcrumbItems.push({ key: 'EditProject', text: "Edit Project" });
                break;
        }
        return breadcrumbItems;
    }

    // Get the current logged in user account
    const { instance } = useMsal();
    const account = instance.getActiveAccount();

    // Name of the steps to create a new efficiency project
    const steps = [
        "Project info",
        "Details info",
        "Review and finish"
    ];

    // State to keep track of the current step
    const [activeStep, setActiveStep] = useState(props.mode === AddProjectTypeEnum.EditProject ? 1 : 0);

    // Get all projects
    const queryClient = useQueryClient();
    const { projects: project, isLoading, queryKey: queryKeyProject } = useEFTrackerBigBetsProject();
    const { results: historyResults, isLoading: isReductionResultsLoading, queryKey: queryKeyReductionResult } = useEFTrackerBigBetsReductionResult(isNaN(uriId) ? 0 : uriId);
    //Get Refresh querykey
    const { queryKey: queryKeyProjectHistory } = useEFTrackerBigBetsProjectHistory(isNaN(uriId) ? 0 : uriId);
    const { refetch: refetchAllLatestByProjectId } = useEFTrackerBigBetsLatestByProjectId([]);
    const { queryKey: queryKeyDiscussion } = useEFTrackerBigBetsDiscussion(isNaN(uriId) ? 0 : uriId);

    // State to keep track of the form data,
    // include all information needed to create a new efficiency project
    const [formData, updateFormData] = useImmer<EFTrackerBigBetsProjectForm>(() => {
        const defaultFormData: EFTrackerBigBetsProjectForm = {
            ProjectId: 0, //Auto add by BE when add
            Name: "",
            Owner: account?.username || "",
            CreateTime: new Date(), //Auto add by BE when add
            LastModifyTime: new Date(), //Auto add by BE when add
            Category: "",
            Workload: "",
            DriName: "",
            DriContact: "",
            ImpactDollar: 0,
            Confidence: "",
            Status: EfficiencyStatusEnum.OnTrack.toString(),
            ETA: new Date(),
            Notes: "",
            LinkToTicket: "",
            CVPSponsor: "",
            GEMGPM: "",
            DateValuePairs: [],
            Discuss: undefined, //Undefined when Key value not change
            FormerKeyValue: "",
        };

        return defaultFormData;
    });

    useEffect(() => {
        if (isLoading) return;
        if (props.mode === AddProjectTypeEnum.EditProject) {
            const thisProject = project.find(item => item.ProjectID === uriId);
            if (!thisProject) {
                // This should never happen
                console.error("Unable to load project with id: ", uriId);
                return;
            }

            const dri = thisProject.DRI.split(";&&");

            // If in edit mode, initialize the form with the project data
            updateFormData(formData => {
                formData.ProjectId = thisProject.ProjectID;
                formData.Name = thisProject.Name;
                formData.Owner = thisProject.Owner;
                formData.CreateTime = thisProject.CreateTime;
                formData.LastModifyTime = thisProject.LastModifyTime;
                formData.Category = thisProject.Category;
                formData.Workload = thisProject.Workload;
                formData.DriName = dri[1] || "";
                formData.DriContact = dri[0]|| "";
                formData.ImpactDollar = thisProject.ImpactDollar;
                formData.Confidence = thisProject.ConfidenceInImpact;
                formData.Status = thisProject.ExecutionStatus;
                formData.ETA = thisProject.DeliveryETA;
                formData.Notes = thisProject.Notes;
                formData.LinkToTicket = thisProject.LinksToExistingTicketItem;
                formData.CVPSponsor = thisProject.Metrics ? (JSON.parse(thisProject.Metrics) as unknown as BigBetsMetrics).CVPSponsor : "";
                formData.GEMGPM = thisProject.Metrics ? (JSON.parse(thisProject.Metrics) as unknown as BigBetsMetrics).GEMGPM : "";
                formData.FormerKeyValue = thisProject.ConfidenceInImpact + thisProject.ExecutionStatus + thisProject.DeliveryETA.toISOString() + thisProject.ImpactDollar.toString();
            });
        }
    }, [isLoading, props.mode, uriId]);

    useEffect(() => {
        if (isReductionResultsLoading) return;

        if (props.mode === AddProjectTypeEnum.EditProject) {
            const thisReductionResult = historyResults[historyResults.length - 1];

            // If in edit mode, initialize the Result with the project data
            if (thisReductionResult) {
                updateFormData(formData => {
                    formData.DateValuePairs = thisReductionResult.DateValuePairs;
                });
            }
        }
    }, [isReductionResultsLoading, props.mode, uriId]);

    // State to keep track of the error messages
    const [errorDict, updateErrorDict] = useImmer<IErrorDict>({});

    const [isSubmitting, { setTrue: submitting, setFalse: submitDone }] = useBoolean(false);

    const handleNext = () => {
        const errors = validateForm();
        if (Object.keys(errors).length !== 0) {
            updateErrorDict(errors);
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleDone = () => {
        if (props.mode === AddProjectTypeEnum.EditProject) {
            gotoPage(`${Pages.EfficiencyTrackerV2}/${projectlink}/${platform}/${Pages.EFSubPage}/${formData.ProjectId}`);
        }
        else {
            gotoPage(`${Pages.EfficiencyTrackerV2}/${projectlink}/${platform}`)
        }
    };

    const handleSubmit = async () => {
        if (!account) {
            updateErrorDict(errorDict => {
                errorDict.SubmitError = "Please login!";
            });
            return;
        }

        const tmpMetrics: BigBetsMetrics = {
            CVPSponsor: formData.CVPSponsor,
            GEMGPM: formData.GEMGPM,
        }

        // Map formData to backend schema:
        const iProject: EFTrackerBigBetsProject = {
            ProjectID: formData.ProjectId, //Auto add by BE when add
            Name: formData.Name,
            Owner: formData.Owner,
            CreateTime: formData.CreateTime, //Auto add by BE when add
            LastModifyTime: formData.LastModifyTime, //Auto add by BE when add
            Category: formData.Category,
            Workload: formData.Workload,
            DRI: formData.DriContact + ";&&" + formData.DriName,
            ImpactDollar: formData.ImpactDollar,
            ConfidenceInImpact: formData.Confidence as EfficiencyStatusEnum,
            ExecutionStatus: formData.Status as EfficiencyStatusEnum,
            DeliveryETA: CompensateLocalDateToUTC(formData.ETA),
            Notes: formData.Notes,
            LinksToExistingTicketItem: formData.LinkToTicket,
            Metrics: JSON.stringify(tmpMetrics),
        };

        const iReductionResult: EFTrackerBigBetsReductionResultForm = {
            Id: 0, //Auto add by BE when add
            ProjectID: formData.ProjectId,
            CreateTime: formData.CreateTime,
            DateValuePairs: JSON.stringify(formData.DateValuePairs),
        }

        const irequest: AddEFTrackerBigBetsProjectRequest = {
            Project: iProject,
            ReductionResult: iReductionResult,
        }
        // Save to backend
        submitting();

        // If edit, update separately, if add, update together.
        const postURL = props.mode === AddProjectTypeEnum.EditProject
            ? 'api/efficiencytracker/bigbets/edit'
            : 'api/efficiencytracker/bigbets/add';

        const postData = props.mode === AddProjectTypeEnum.EditProject
            ? iProject
            : irequest;
        const response = await handlePostRequest(postURL, postData);

        if (response) {
            let editReductionAndDiscuss = true;
            if (props.mode === AddProjectTypeEnum.EditProject) {
                editReductionAndDiscuss = await handlePostRequest('api/efficiencytracker/bigbets/addreductionresult', iReductionResult);
                if (formData.Discuss) { // If key Value change, add note
                    const discussionTmp: EFTrackerBigBetsDiscussion = {
                        Id: 0,
                        ProjectID: formData.ProjectId,
                        CreateTime: new Date(),
                        Note: formData.Discuss,
                        Owner: account?.username || "",
                    }

                    editReductionAndDiscuss = await handlePostRequest('api/efficiencytracker/bigbets/addnote', discussionTmp);
                }
            }

            if (editReductionAndDiscuss) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);

                //Invalidate cache
                queryClient.invalidateQueries(queryKeyProject);
                queryClient.invalidateQueries(queryKeyReductionResult);
                queryClient.invalidateQueries(queryKeyProjectHistory);
                queryClient.invalidateQueries(queryKeyDiscussion);

                refetchAllLatestByProjectId();
            }
        }

        submitDone();
    };

    const handlePostRequest = async (postURL: string, postData: any): Promise<boolean> => {
        try {
            const response = await postMethod(postURL, postData);
            const responseData = await response.text();
            if (response.ok) {
                updateErrorDict((errorDict) => {
                    if ("SubmitError" in errorDict) {
                        delete errorDict.SubmitError;
                    }
                });
                return true;
            } else {
                updateErrorDict((errorDict) => {
                    errorDict.SubmitError = responseData || "New request upload failed! Please try again later";
                });
                return false;
            }
        } catch (error) {
            updateErrorDict((errorDict) => {
                errorDict.SubmitError = 'Error when uploading New request! ' + error;
            });
            return false;
        }
    };

    function validateForm(): IErrorDict {
        const errors: IErrorDict = {};
        switch (activeStep) {
            // Validation for Basic project info
            case 0:
                if (!formData.Category) {
                    errors.CategoryError = "Category can not be empty!";
                }
                if (!formData.Workload) {
                    errors.WorkloadError = "Workload can not be empty!";
                }
                if (!formData.Name) {
                    errors.ProjectNameError = "Project name can not be empty!";
                }
                if (formData.Name.length > 512) {
                    errors.ProjectNameError = "Project name contains a maximum of 512 characters!";
                }
                if (!formData.DriName) {
                    errors.DriNameError = "DRI Name can not be empty!";
                }
                if (!formData.DriContact) {
                    errors.DriEmailError = "DRI Email can not be empty!";
                }
                break;
            // Validation for Resource info
            case 1:
                if (!formData.Confidence) {
                    errors.ConfidenceLevelError = "Confidence level not selected!";
                }
                if (!formData.Status) {
                    errors.ExecutionStatusError = "Execution Status can not be empty!";
                }
                if (formData.ImpactDollar < 0) {
                    errors.ImpactDollarError = "Impact dollar must be greater than or equal to 0!";
                }
                if (formData.ImpactDollar === 0 && formData.Status !== EfficiencyStatusEnum.Draft) {
                    errors.ImpactDollarError = "Impact dollar must be greater than 0 if you don't create a Draft project!";
                }
                if (formData.DateValuePairs.length === 0) {
                    errors.AddProjectDetailsError = "Details info can not be empty!";
                }
                if (formData.Discuss === "" && props.mode === AddProjectTypeEnum.EditProject) {
                    errors.AddReasonError = "Please Add the reason for changing the Impact/Confidence/Execution Status";
                }
                break;
            default:
                break;
        }
        return errors;
    }

    const curForm = () => {
        switch (activeStep) {
            case 0:
                return <NewBigBetsProjectInfo
                    formData={formData}
                    updateFormData={updateFormData}
                    errorDict={errorDict}
                    updateErrorDict={updateErrorDict}
                    mode={props.mode}
                />;
            case 1:
                return <BigBetsReductionPlan
                    formData={formData}
                    updateFormData={updateFormData}
                    errorDict={errorDict}
                    updateErrorDict={updateErrorDict}
                    mode={props.mode}
                />;
            case 2:
                return <BigBetsConfirmation
                    formData={formData}
                    setActiveStep={setActiveStep}
                    errorDict={errorDict}
                    confirmed={false}
                    editMode={props.mode === AddProjectTypeEnum.EditProject}
                />;
            case 3:
                return <BigBetsConfirmation
                    formData={formData}
                    setActiveStep={setActiveStep}
                    errorDict={errorDict}
                    confirmed={true}
                    editMode={props.mode === AddProjectTypeEnum.EditProject}
                />;
            default:
                return <></>;
        }
    }


    return (
        <div className={styles.container}>

            <div className={styles.breadcrumbContainer}>
                <Breadcrumb items={getBreadcrumbItems()} />
            </div>

            <div className={styles.splitPanes}>

                <div className={styles.stepsContainer}>
                    <Box sx={{ maxWidth: 280 }}>
                        <Stepper activeStep={activeStep}
                            orientation="vertical"
                            // Turn stepper to green when all done
                            sx={activeStep === steps.length ? {
                                '& .MuiStepIcon-root': { color: '#00AA00' }
                            } : {}}>
                            {steps.map((stepText, index) => (
                                <Step key={stepText}>
                                    <StepLabel> {stepText} </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </div>

                <div className={styles.formContainer}>
                    {curForm()}
                </div>

            </div>

            <div className={styles.bottomContainer}>
                {activeStep === steps.length ? (
                    <PrimaryButton
                        text="Done"
                        onClick={handleDone}
                    />

                ) : (
                    <>
                        <Stack horizontal tokens={{ childrenGap: 16 }}>
                            <DefaultButton
                                text="Back"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                            />
                            {activeStep === steps.length - 1 ? (
                                <PrimaryButton
                                    text={`Confirm and submit ${props.mode === AddProjectTypeEnum.EditProject ? "changes" : ""}`}
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                />
                            ) : (
                                <PrimaryButton
                                    text="Next"
                                    onClick={handleNext}
                                />
                            )}
                        </Stack>
                        <DefaultButton
                            text="Close"
                            onClick={handleDone}
                            styles={{ root: { marginLeft: 'auto' } }}
                        />
                    </>
                )}

            </div>

        </div>
    );

}

export default AddProjectView;