// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VNj4BbufDEoAzkK50WAh {\n  color: #323130;\n  font-family: \"Segoe UI\";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 20px;\n  padding-bottom: 10px;\n}\n.WcK_qnGU2GlO6gw3QWr3::before {\n  background-color: #323130;\n}\n.JR4sPcO_uq3dcXwJKPVI {\n  padding: 5px 24px 5px 10px;\n}\n.Cwb28fSnxS8mbI4ElPu8 {\n  background-color: transparent;\n  transition: none;\n  margin-bottom: -3px;\n  cursor: pointer;\n}\n.Cwb28fSnxS8mbI4ElPu8:hover {\n  color: ActiveCaption;\n  background-color: transparent;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/EfficiencyTrackerV2/Components/BigBets/BigBetsDetails.less"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,oBAAA;AACJ;AAEA;EACI,yBAAA;AAAJ;AAGA;EACI,0BAAA;AADJ;AAIA;EACI,6BAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;AAFJ;AAKA;EACI,oBAAA;EACA,6BAAA;EACA,eAAA;AAHJ","sourcesContent":[".title {\n    color: #323130;\n    font-family: \"Segoe UI\";\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 20px;\n    padding-bottom: 10px;\n}\n\n.separator::before {\n    background-color: #323130;\n}\n\n.addNote {\n    padding: 5px 24px 5px 10px;\n}\n\n.ExplainIcon {\n    background-color: transparent;\n    transition: none;\n    margin-bottom: -3px;\n    cursor: pointer;\n}\n\n.ExplainIcon:hover {\n    color: ActiveCaption;\n    background-color: transparent;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "VNj4BbufDEoAzkK50WAh",
	"separator": "WcK_qnGU2GlO6gw3QWr3",
	"addNote": "JR4sPcO_uq3dcXwJKPVI",
	"ExplainIcon": "Cwb28fSnxS8mbI4ElPu8"
};
export default ___CSS_LOADER_EXPORT___;
