import PageHeader, { IPageHeaderProps } from '../common/PageHeader';

import React from 'react';
import TelemetryChart from './chart/TelemetryChart';
import TelemetryCostTable from './TelemetryCostTable';
import { TelemetrySourceTypeEnum } from '../../models/TelemetryCogsModel';
import { WarningBanner } from '../banner/WarningBanner';
import styles from './TelemetryView.less';
import { useGetBannersByTabAndDateQuery } from '../../hooks/useBannerQuery';

const TelemetryViewHeadersProps: Record<TelemetrySourceTypeEnum, IPageHeaderProps> = {
    [TelemetrySourceTypeEnum.All]: {
        title: "Telemetry",
        description: "Major telemetry data fabrics (Cosmos, Blueshift, Kusto, Geneva, Aria), and all corresponding data assets.",
        link: "https://dev.azure.com/O365Exchange/O365%20Core/_wiki/wikis/O365%20Core.wiki/63980/Cosman",
        linkText: "Learn more about Telemetry Cost",
    },
    [TelemetrySourceTypeEnum.Cosmos]: {
        title: "Cosmos",
        description: "The Cosmos cost shown in this view is based on usage. It is not the actual billed cost but is displayed to illustrate the usage.",
        link: "https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/286531/Cosman-cost-calculation-model",
        linkText: "Learn more about Cosmos cost model",
    },
    [TelemetrySourceTypeEnum.Blueshift]: {
        title: "Blueshift",
        description: "The Blueshift cost shown in this view is based on usage. It is not the actual billed cost but is displayed to illustrate the usage.",
        link: "https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/286531/Cosman-cost-calculation-model",
        linkText: "Learn more about Blueshift cost model",
    },
    [TelemetrySourceTypeEnum.Kusto]: {
        title: "Kusto",
        description: "Kusto service is running on VM and using multiple resources in Azure. From the COGS point of view, we want to list all COGS caused by Kusto, so we summarized all COST including VM, Storage, Bandwidth, etc. together as the Kusto COGS.",
        link: "https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/286531/Cosman-cost-calculation-model",
        linkText: "Learn more about Kusto cost model",
    },
    [TelemetrySourceTypeEnum.GenevaMDS]: {
        title: "GenevaMDS",
        description: "Geneva MDS COGS = the cost of Azure Storage account + Azure Event hub which created by Geneva service.",
        link: "https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/286531/Cosman-cost-calculation-model",
        linkText: "Learn more about GenevaMDS cost model",
    },
    [TelemetrySourceTypeEnum.GenevaMDM]: {
        title: "GenevaMDM",
        description: "Geneva MDM has 2 stamps, dedicated stamp and shared stamp. Currently Geneva MDM does not charge from shared stamp, so our COGS does not include shared part. So, the Geneva MDM cost is the subscription cost of dedicated stamp.",
        link: "https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/286531/Cosman-cost-calculation-model",
        linkText: "Learn more about GenevaMDM cost model",
    },
    [TelemetrySourceTypeEnum.Aria]: {
        title: "Aria",
        notification: '',
        description: "",
        link: "https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/286531/Cosman-cost-calculation-model",
        linkText: "Learn more about Aria cost model",
    }
};

interface ITelemetryViewProps {
    type: TelemetrySourceTypeEnum
}

const TelemetryView: React.FC<ITelemetryViewProps> = (props) => {
    const { data: allBanners } = useGetBannersByTabAndDateQuery(TelemetrySourceTypeEnum[props.type].toString());

    return (
        <div className={styles.telemetryView}>
            <PageHeader {...TelemetryViewHeadersProps[props.type]} className={styles.header} />
            <div className={styles.Bannerheader} >
                {
                    Boolean(allBanners?.length) &&
                    allBanners?.map(item =>
                    (
                        <WarningBanner key={item.id} bannerItem={item} />
                    ))
                }
            </div>
            <TelemetryChart type={props.type}/>
            <TelemetryCostTable type={props.type} />
        </div>
    );
};

export default TelemetryView;