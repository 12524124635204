import { IPassiveMonitoringCost, IPassiveMonitoringResourceCost, PassiveMonitoringLogComponent, PassiveMonitoringPlatformEnum, PassiveMonitoringPlatformName } from "../../../models/TelemetryCogsModel";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";

import ChartContainer from "../../chart/ChartContainer";
import CommonConstants from "../../../models/constants/CommonConstants";
import { CostCardProps } from "../../common/costCard/CostCard";
import { LineChartSeries } from "../../common/LineChart";
import React from "react";
import { currencyFormatter } from "../../../utils/currency";
import moment from 'moment';
import { useGetPassiveMonitoringCost } from "../../../hooks/useTelemetryCost";
import { useTrackHovering } from "../../../hooks/useTrack";

interface IPassiveMonitoringTrendChartProps {
    title: string;
    sources: PassiveMonitoringPlatformEnum[];
}

const formatData = (value: number) => {
    return currencyFormatter(value, 2, "$");
};

const formatAxisData = (value: number) => {
    return currencyFormatter(value, 2, "$");
};

function getPassiveMonitoringCost(source: PassiveMonitoringPlatformEnum, passiveMonitoringCost: IPassiveMonitoringResourceCost) {
    switch (source) {
        case PassiveMonitoringPlatformEnum.All:
            return passiveMonitoringCost.kusto + passiveMonitoringCost.genevaMds;
        case PassiveMonitoringPlatformEnum.GenevaMDS:
            return passiveMonitoringCost.genevaMds;
        case PassiveMonitoringPlatformEnum.Kusto:
            return passiveMonitoringCost.kusto;
        default:
            return 0;
    }
}

const PassiveMonitoringTrendChart: React.FC<IPassiveMonitoringTrendChartProps> = ({ sources, title }) => {
    const query = useGetPassiveMonitoringCost();

    const summarizedData = query.data?.reduce<Record<string, IPassiveMonitoringResourceCost>>((acc, item) => {
        const key = moment(item.timeStamp).format('YYYY-MM-DD');
        if (!acc[key]) {
          acc[key] = {
            timeStamp: item.timeStamp,
            genevaMds: 0,
            kusto: 0,
            total: 0,
          };
        }
        acc[key].genevaMds += item.genevaMds;
        acc[key].kusto += item.kusto;
        acc[key].total += item.total;
        return acc;
      }, {});
      
    const result: IPassiveMonitoringResourceCost[] = Object.values(summarizedData || []);

    const cards: CostCardProps[] = sources.map((source, index) => ({
        title: `Total ${PassiveMonitoringPlatformName[source]} Cost`,
        cost: (result && result.reduce((sum, val) => sum + getPassiveMonitoringCost(source, val) || 0, 0)) || 0,
        color: CommonConstants.DefaultColors[index],
        formatter: (value: number) => formatData(value),
    }));

    const series: LineChartSeries[] = sources.map((source, index) => ({
        color: CommonConstants.DefaultColors[index],
        name: PassiveMonitoringPlatformName[source],
        data: result && result.map((singleMetric) => [singleMetric.timeStamp.valueOf(), getPassiveMonitoringCost(source, singleMetric)]),
    }));

    const trackChartHovering = useTrackHovering(LogComponent.PassiveMonitoringView, LogElement.TelemetryTrendsChart, title, LogTarget.Chart, {
        dataSources: sources.map((item) => PassiveMonitoringPlatformName[item]).join(","),
    });

    return (
        <ChartContainer
            headerProps={{ title }}
            loading={query.isLoading}
            cards={cards}
            chartType="line"
            chartProps={{
                series,
                height: 220,
                tooltipYFormatter: (value: number) => formatData(value),
                yFormatter: (value: number) => formatAxisData(value),
                containerProps: trackChartHovering,
            }}
            logProps={{
                logComponent: PassiveMonitoringLogComponent[sources.length > 1 ? PassiveMonitoringPlatformEnum.All : sources[0]],
                logElement: LogElement.TelemetryTrendsChart,
            }}
        />
    );
};

export default PassiveMonitoringTrendChart;
