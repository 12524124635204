import { IPassiveMonitoringCost, ITelemetryResourceCost, ITelemetryServiceCost, ITelemetryTotalCost } from "../models/TelemetryCogsModel";
import { getPassvieMonitoringCogs, getResourceTelemetryCogs, getServiceTelemetryCogs, getTotalTelemetryCogs } from "../services/TelemetryCogsService";

import { Endpoints } from "../utils/Constants";
import { IAppState } from "../store";
import { ServiceTreeItem } from "../models/serviceTree";
import { useCategoryFilters } from "./useFilters";
import { useDateRange } from "./useDateSelector";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

export function useGetTelemetryServiceCost(limit: number) {
    const globalFilters = useCategoryFilters().filters;
    const range = useDateRange();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);

    return useQuery<
        ITelemetryServiceCost[],
        Error,
        ITelemetryServiceCost[],
        Parameters<typeof getServiceTelemetryCogs>['0']['queryKey']
    >([Endpoints.GetTelemetryServiceCogs, globalFilters.filters, serviceIdMap, range.startDate, range.endDate, limit], getServiceTelemetryCogs);
}

export function useGetTelemetryResourceCost() {
    const globalFilters = useCategoryFilters().filters;
    const range = useDateRange();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);

    return useQuery<
        ITelemetryResourceCost[],
        Error,
        ITelemetryResourceCost[],
        Parameters<typeof getResourceTelemetryCogs>['0']['queryKey']
    >([Endpoints.GetTelemetryResourceCogs, globalFilters.filters, serviceIdMap, range.startDate, range.endDate], getResourceTelemetryCogs);
}

export function useGetTelemetryTotalCost() {
    const globalFilters = useCategoryFilters().filters;
    const range = useDateRange();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);

    return useQuery<
        ITelemetryTotalCost[],
        Error,
        ITelemetryTotalCost[],
        Parameters<typeof getTotalTelemetryCogs>['0']['queryKey']
    >([Endpoints.GetTelemetryTotalCogs, globalFilters.filters, serviceIdMap, range.startDate, range.endDate], getTotalTelemetryCogs);
}

export function useGetPassiveMonitoringCost() {
    const globalFilters = useCategoryFilters().filters;
    const range = useDateRange();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);

    return useQuery<
        IPassiveMonitoringCost[],
        Error,
        IPassiveMonitoringCost[],
        Parameters<typeof getPassvieMonitoringCogs>['0']['queryKey']
    >([Endpoints.GetPassiveMonitoringCogs, globalFilters.filters, serviceIdMap, range.startDate, range.endDate], getPassvieMonitoringCogs);
}